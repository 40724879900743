import { bg2, bg3, bg4, bg6, bg7, item1, item2, item3, item4, item5 } from "assets/img";
import { palette } from "modules/defines/styles";
import styled, { keyframes } from "styled-components";
import { video_intro, video_first } from "assets/video";
import { ButtonSolid } from "components/common/Button";
import Footer from "components/Layout/Footer";
import Header from "components/Layout/Header";
import { Body_02, H_01, H_03, H_04, Sub_02, Text_01, Text_02 } from "modules/style/typography";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Marquee from "react-fast-marquee";
import { useEffect, useRef } from "react";
import device from "modules/defines/device";

gsap.registerPlugin(ScrollTrigger);

const FixButton = () => {
  const buttonRef = useRef(null);

  useEffect(() => {
    gsap.from(buttonRef.current, {
      scrollTrigger: {
        trigger: buttonRef.current,
        start: "top 75%",
        toggleActions: "play none none reverse",
      },
      autoAlpha: 0,
      duration: 1,
      delay: 0.6,
    });
  }, []);

  return (
    <FixedButton
      onClick={() => window.open(`https://faview.ai/portal/mypage`)}
      type="button"
      ref={buttonRef}
    >
      무료로 제작하기
    </FixedButton>
  );
};
const WelcomeContainer: React.FC = () => {
  const isDesktopOrTablet = () => {
    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = /Mobile|Android|BlackBerry|IEMobile|Opera Mini|IEMobile/.test(userAgent);
    const iPad = /iPad/.test(userAgent);
    return !mobile || iPad;
  };

  const imageItems = [
    { src: item4, link: "couch_social" },
    { src: item1, link: "아키바코믹스" },
    { src: item2, link: "our.old.mill" },
    { src: item3, link: "odd" },
    { src: item5, link: "성수장어" },
    { src: item1, link: "아키바코믹스" },
    { src: item2, link: "our.old.mill" },
    { src: item3, link: "odd" },
  ];

  return (
    <>
      <Header />
      <Container>
        <ContentsContainer>
          <FirstSection>
            <FirstTitleWrapper>
              <video autoPlay loop muted playsInline>
                <source src={video_intro} type="video/mp4" />
              </video>
              <figcaption>
                <h2>
                  <div>내가 만드는 3D</div>
                  <div>공간 뷰어,</div>
                </h2>
                <FirstSubTitle>스마트폰으로 5분 만에</FirstSubTitle>
                <FirstDescription>파뷰는 어디서나 자유롭게 이용할 수 있어요</FirstDescription>
                <ButtonSolid
                  mode={"dark"}
                  size={"L"}
                  marginTop={"16px"}
                  onClick={() => window.open(`https://faview.ai/portal/mypage`)}
                >
                  무료로 제작하기
                </ButtonSolid>
              </figcaption>
            </FirstTitleWrapper>
          </FirstSection>
          <GradientWrapper>
            <IntroduceDescriptionWrapper textAlign="center" paddingTop="0" positionType="static">
              <IntroduceDescriptionSubject maxWidth="396px">
                실제 공간을 생생하게 담아드립니다
              </IntroduceDescriptionSubject>
              <p>
                파뷰는 360도 공간 뷰어로, 온라인에서도
                <br /> 오프라인과 같은 공간 경험이 가능한 서비스입니다.
              </p>
            </IntroduceDescriptionWrapper>

            <FirstSectionVideo src={video_first} muted autoPlay loop playsInline />
          </GradientWrapper>
          <SecondSection>
            <img src={bg2} alt="" />
            <IntroduceDescriptionWrapper positionType="static" position="0" paddingTop="50px">
              <IntroduceDescriptionSubject>누구든지 시작할 수 있어요</IntroduceDescriptionSubject>
              <p>
                복잡한 장비와 AI 기술의 장벽은
                <br /> 파뷰가 줄여드리고 스마트폰 동영상 촬영으로
                <br /> 3D 공간뷰어를 만들 수 있습니다.
              </p>
            </IntroduceDescriptionWrapper>
          </SecondSection>

          <NoGradientIntroduceSection>
            <Img2 src={bg3} alt="" />
            <CardText>
              <CardTextTitle>어디서나 자유롭게 볼 수 있어요</CardTextTitle>
              <CardTextDescription>
                파뷰는 웹 기반 서비스로, 별도의 프로그램 없이
                <wbr />
                링크 하나로 스마트폰, 태블릿, PC에서 확인하고
                <wbr />
                공유할 수 있습니다.
              </CardTextDescription>
            </CardText>
          </NoGradientIntroduceSection>
          <IntroduceSection>
            <Img src={bg4} alt="" />
            <CardText mobileMarinTop={406} marginLeft={1040}>
              <CardTextTitle>
                이제껏 없었던 <br /> 새로운 마케팅 수단
              </CardTextTitle>
              <CardTextDescription>
                오프라인 경험을 온라인에서 먼저 경험하는
                <wbr />
                파뷰만의 팬덤이 모여있는 국내 최대 3D 뷰어
                <wbr />
                포탈을 통해 새로운 마케팅을 시작해 보세요.
              </CardTextDescription>
            </CardText>
          </IntroduceSection>

          <NoImageSection>
            <SectionTitle>
              쉽고 빠르게,
              <br />
              파뷰로 만들었습니다.
            </SectionTitle>

            {isDesktopOrTablet() ? (
              <ImageCarouselWrapper>
                <Marquee speed={100} pauseOnHover={true}>
                  <ImageCarousel>
                    {imageItems.map((item, index) => (
                      <ImageItem
                        key={index}
                        src={item.src}
                        onClick={() => window.open(`https://faview.io/view/${item.link}`)}
                      />
                    ))}
                  </ImageCarousel>
                </Marquee>
                <Button onClick={() => window.open(`https://faview.ai/portal`)} type="button">
                  더보기
                </Button>
              </ImageCarouselWrapper>
            ) : (
              <ImageCarousel>
                {imageItems.map((item, index) => (
                  <ImageItem
                    key={index}
                    src={item.src}
                    onClick={() => window.open(`https://faview.io/view/${item.link}`)}
                  />
                ))}
                <Button type="button">더보기</Button>
              </ImageCarousel>
            )}
          </NoImageSection>

          <Section
            gradientType="linear"
            gradientColors={{
              start: "rgba(51, 51, 51, 0.20)",
              end: "rgba(153, 153, 153, 0.00)",
            }}
            bgUrl={bg6}
          >
            <SectionTextWrapper2>
              <ButtonSolid
                mode={"dark"}
                size={"L"}
                marginTop={"28px"}
                onClick={() => window.open(`https://faview.ai/portal/mypage`)}
              >
                문의하기
              </ButtonSolid>
            </SectionTextWrapper2>
          </Section>
          <Section
            gradientType="linear"
            gradientColors={{
              start: "rgba(0, 0, 0, 0.5)",
              end: "rgba(0, 0, 0, 0.5)",
            }}
            bgUrl={bg7}
          >
            <SectionTextWrapper>
              <ButtonSolid
                mode={"dark"}
                size={"L"}
                marginTop={"40px"}
                onClick={() => window.open(`https://faview.ai/portal/mypage`)}
              >
                무료로 제작하기
              </ButtonSolid>
            </SectionTextWrapper>
          </Section>
        </ContentsContainer>
      </Container>
      <Footer mode="light" />
      <FixButton />
    </>
  );
};

const FixedButton = styled.button`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000005; /* 채널톡 z-index --> 10000000 */
  color: #fff;
  ${Text_02};
  padding: 16px 0;
  .isMobile && {
    width: calc(100% - 30px);
    ${device.mobile428} {
      width: calc(100% - 40px);
    }
  }
  .isTablet && {
    width: calc(100% - 40px);
  }
  /* white-space: nowrap; */

  border-radius: 4px;
  background-color: ${palette.primaryNormal};

  .isDesktop && {
    display: none;
  }
`;

const FirstSection = styled.section`
  position: relative;
`;

const FirstSubTitle = styled.div`
  display: block;
  color: white;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  ${Text_02};
  .isDesktop && {
    ${H_01};
  }
`;

const FirstDescription = styled.p`
  color: white;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */

  max-width: 168px;
  .isDesktop && {
    color: white;
    max-width: 100%;
    ${Sub_02}
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const FirstTitleWrapper = styled.figure`
  &::before {
    content: "";
    bottom: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 280px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 85.5%);
    z-index: 3;
  }
  background: transparent;
  height: 920px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > video {
    pointer-events: none;
    margin-top: -60px;
    width: 100%;
    height: 1000px;
    object-fit: cover;
    opacity: 0.7;
    position: absolute;
    z-index: -1;
  }

  .isMobile &&,
  .isTablet && {
    & > figcaption {
      display: grid;
      place-items: center;
      row-gap: 24px;
      z-index: 9999;
      & > h2 {
        & > div {
          color: white;
          text-align: center;
          text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
          font-size: 32px;
          font-weight: 700;
          line-height: 48px; /* 150% */
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
        }
      }
    }
  }

  .isDesktop && {
    & > figcaption {
      display: grid;
      place-items: center;

      z-index: 9999;
      & > h2 {
        display: flex;
        & > div {
          color: white;
          text-align: center;
          text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
          ${H_01};
        }
      }
    }
  }
`;

const CardText = styled.div<{ marginLeft?: number; mobileMarinTop?: number }>`
  .isMobile &&,
  .isTablet && {
    display: grid;
    grid-template-rows: fit-content(100%);
    row-gap: 16px;
    z-index: 20;
    margin-top: ${(props) => (props.mobileMarinTop ? `${props.mobileMarinTop}px` : "346px")};
  }
  .isDesktop && {
    display: grid;
    grid-template-rows: fit-content(100%);
    row-gap: 28px;
    z-index: 20;
    margin-left: 100px;
    margin-bottom: 65px;
    @media (min-width: 1920px) {
      margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}px` : "240px")};
      margin-bottom: 0;
    }
  }
`;

const CardTextTitle = styled.em`
  color: white;
  .isMobile && {
    ${Text_01};
    text-align: center;
    max-width: fit-content;
    margin: 0 auto;
  }

  .isTablet && {
    ${Text_01};
  }
  .isDesktop && {
    ${H_03};
  }
`;

const CardTextDescription = styled.p`
  color: white;
  .isMobile && {
    margin: 0 auto;
    max-width: 255px;
    ${Body_02};
    word-break: break-all;
    overflow-wrap: break-word;
    text-align: center;
  }

  .isTablet && {
    ${Body_02};
    word-break: break-all;
    overflow-wrap: break-word;
    text-align: center;
    max-width: 310px;
  }
  .isDesktop && {
    white-space: pre-line;
    ${Sub_02};
    max-width: 370px;
  }
`;
const SecondSection = styled.section`
  border-top: 8px solid #2b2b2b;
  background-color: #000;
  min-height: 700px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 10%;
    top: 380px;
    background: linear-gradient(360deg, #000 45%, rgba(0, 0, 0, 0) 100%);
  }
  & > img {
    width: 100%;
    object-fit: cover;
  }

  .isTablet && {
    height: 708px;
    &::before {
      height: 200px;
      top: 300px;
      opacity: 0.8;
    }
    & > div:last-child {
      padding-bottom: 136px;
    }
  }

  .isDesktop && {
    &::before {
      display: none;
    }

    position: relative;
    & > img {
      width: 100%;
      height: 720px;
      object-fit: cover;
      border: 0;
    }
    & > div {
      width: 100%;
      height: 100%;
      position: absolute;
      padding-top: 500px;
    }
    @media (min-width: 1920px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: -120px;
      position: relative;
      &::after {
        content: "";
        top: 50%;
        transform: translate(0, -50%);
        width: 50%;
        height: 100%;
        position: absolute;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 54.27%, #000 76.72%);
      }
      & > img {
        width: 50%;
        height: 720px;
      }

      & > div {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 0;

        /* padding-top: calc(60% - 10px); */
      }
    }
  }
`;

const GradientWrapper = styled.section`
  background-color: #000;
  border-top: 8px solid #2b2b2b;
  .isMobile && {
    display: grid;
    width: 100%;
    padding: 160px 0 80px 0;
    grid-template-columns: 1fr;
    row-gap: 70px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 220px;
      bottom: 79px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 45.73%, #000 100%),
        linear-gradient(270deg, rgba(0, 0, 0, 0) 54.27%, #000 97.81%), transparent;
    }
  }
  .isTablet && {
    display: grid;
    width: 100%;
    padding: 160px 0 80px 0;
    grid-template-columns: 1fr;
    row-gap: 70px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 250px;
      bottom: 80px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 45.73%, #000 100%),
        linear-gradient(270deg, rgba(0, 0, 0, 0) 54.27%, #000 97.81%), transparent;
    }
  }
  .isDesktop && {
    padding: 80px 0px 0px 0px;
    display: grid;

    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 80px;
    position: relative;
    & > div {
      align-items: center;
    }
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 720px;
      bottom: 0px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 45.73%, #000 100%),
        linear-gradient(270deg, rgba(0, 0, 0, 0) 54.27%, #000 97.81%), transparent;
    }
    @media (min-width: 1920px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0px;
      position: relative;
      & > div {
        padding-left: 340px;
        align-items: flex-start;
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 50%;
        height: 100%;
        right: 0;
        bottom: 0px;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 54.27%, #000 97.81%), transparent;
      }
    }
  }
`;
type GradientType = "linear" | "linearDegree";
interface GradientColors {
  start: string;
  end: string;
}

export function createGradient(type: GradientType, colors: GradientColors) {
  switch (type) {
    case "linear":
      return `linear-gradient(${colors.start}, ${colors.end})`;
    case "linearDegree":
      return `linear-gradient(180deg, ${colors.start}, ${colors.end})`;
    default:
      return "";
  }
}

interface BackgroundProps {
  gradientColors: GradientColors;
  gradientType: GradientType;
  bgUrl: string;
}

const ImageCarouselWrapper = styled.div`
  margin-top: 50px;
  .isDesktop && {
    margin-top: 40px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Section = styled.section<BackgroundProps>`
  position: relative;
  background: ${(props) => createGradient(props.gradientType, props.gradientColors)},
    url(${(props) => props.bgUrl});
  background-blend-mode: multiply, normal;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 720px;
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;

  /* margin-top: 70px; */
  background-color: ${palette.background};
`;

const Img = styled.img`
  position: absolute;
  z-index: 0;
  .isMobile && {
    width: 600px;
    left: -48px;
  }
  .isTablet && {
    width: 600px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .isDesktop && {
    width: 728px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    @media (min-width: 1920px) {
      width: 960px;
      height: 710px;
      left: 0;
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 50%;
        height: 729px;
        bottom: 0px;
        z-index: 10;
      }
    }
  }
`;

const Img2 = styled(Img)`
  position: absolute;
  z-index: 0;
  .isMobile && {
    width: 100%;
    left: 0;
    top: 140px;
  }
  .isTablet && {
    width: 360px;
    top: 160px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .isDesktop && {
    width: 728px;
    top: 45%;
    transform: translateY(-50%);
    right: 0;

    @media (min-width: 1920px) {
      width: 960px;
      top: 55%;
      transform: translateY(-50%);

      left: calc(100% - 960px);
    }
  }
`;

const IntroduceDescriptionWrapper = styled.div<{
  position?: string;
  positionType?: string;
  paddingTop?: string;
  textAlign?: string;
}>`
  z-index: 10;
  position: ${(props) => props.positionType || "absolute"};
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.paddingTop || "74px"};
  & > p {
    ${Body_02};
    color: ${palette.white};
  }

  .isMobile &&,
  .isTablet && {
    bottom: 128px;
  }

  .isDesktop && {
    & > p {
      ${Sub_02};
    }
    text-align: ${(props) => props.textAlign || "left"};
    bottom: 65px;
    left: 100px;
    height: 100%;
    align-items: flex-start;
    gap: 28px;
    @media (min-width: 1920px) {
      min-width: 960px;
      height: 100%;
      bottom: 65px;
      text-align: left;
      justify-content: center;
      left: ${(props) => `calc(100% - ${props.position || "1080px"})`};
      top: 0;
    }
  }
`;

const IntroduceDescriptionSubject = styled.strong<{ maxWidth?: string }>`
  color: ${palette.white};
  ${Text_01};
  .isDesktop && {
    max-width: ${(props) => props.maxWidth || "100%"};
    ${H_03};
  }
`;

const IntroduceSection = styled.section`
  background-color: #2a2a2a;
  border-top: 8px solid #2b2b2b;
  width: 100%;
  height: 100%;
  min-height: 720px;
  position: relative;

  .isMobile && {
    display: flex;

    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      display: block;
      height: calc(100% - 136px);
      opacity: 0.5;
      z-index: 1;
      right: 0;
      width: 33.33%;
      background: linear-gradient(270deg, #000 35.08%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .isTablet && {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 20px;
    &::before {
      content: "";
      position: absolute;
      display: block;
      height: calc(100% - 136px);
      opacity: 0.5;
      z-index: 1;
      right: 0;
      width: 100%;
      opacity: 0.5;
      background: linear-gradient(
        90deg,
        #000 10%,
        rgba(0, 0, 0, 0.1) 20%,
        transparent 10%,
        transparent 80%,
        rgba(0, 0, 0, 0.1) 20%,
        #000 100%
      );
    }
  }

  .isMobile &&,
  .isTablet && {
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 50%;
      bottom: 0px;
      background: linear-gradient(360deg, #000 33.32%, rgba(0, 0, 0, 0) 100%);
    }
  }
  .isDesktop && {
    background: #262626;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      margin: 0;
      height: 50%;
      bottom: -20px;
      background: linear-gradient(360deg, #000 33.32%, rgba(0, 0, 0, 0) 100%);
    }

    @media (min-width: 1920px) {
      height: 720px;
      background: #000;
      align-items: center;

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 50%;
        height: 100%;
        bottom: 0px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 66.35%, #000 97.81%);
      }
    }
  }
`;

const NoGradientIntroduceSection = styled.section`
  background-color: #1b1b1c;
  border-top: 8px solid #2b2b2b;
  width: 100%;
  height: 100%;
  min-height: 720px;
  position: relative;

  .isMobile && {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 50%;
      bottom: 0px;
      background: linear-gradient(360deg, #000 45%, rgba(0, 0, 0, 0) 100%);
    }
  }

  .isTablet && {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 50%;
      bottom: 0px;
      background: linear-gradient(360deg, #000 45%, rgba(0, 0, 0, 0) 100%);
    }
  }
  .isDesktop && {
    background: #1b1b1c;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      margin: 0;
      height: 50%;
      bottom: -20px;
      background: linear-gradient(360deg, #000 45%, rgba(0, 0, 0, 0) 100%);
    }

    @media (min-width: 1920px) {
      justify-content: flex-start;
      align-items: center;

      background: #1b1b1c;

      &::after {
        position: absolute;
        display: block;
        width: 70%;
        height: 100%;
        bottom: 0px;
        background: linear-gradient(90deg, #000 10%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }
`;

const ContentsContainer = styled.main`
  display: flex;
  flex-direction: column;
`;

const SectionTextWrapper2 = styled.div`
  position: absolute;
  width: 100%;
  top: 268px;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::before {
    content: "더 높은 퀄리티의 영상을 원한다면,\n전문가에게 맡겨보세요";
    max-width: 286px;

    color: ${palette.white};
    text-align: center;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    ${Text_01};
  }
  .isDesktop && {
    &::before {
      content: "더 높은 퀄리티의 영상을 원한다면, 전문가에게 맡겨보세요";
      max-width: 545px;
      ${H_03};
    }
  }
`;

const NoImageSection = styled.section`
  background-color: white;
  .isMobile && {
    display: flex;
    flex-direction: column;
    padding: 100px 0 30px 0;
  }
  .isTablet && {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
  }
  .isDesktop && {
    padding-top: 136px;
    padding-bottom: 10px;
  }
`;

const SectionTextWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 307px;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &::before {
    content: "내가 만드는 \u2029 3D 공간뷰어,";
    max-width: 171px;
    width: 100%;
    height: 100%;
    color: ${palette.white};
    text-align: center;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
    ${H_04};
  }
  .isDesktop && {
    &::before {
      content: "지금 무료로 시작해 보세요!";
      max-width: fit-content;
      ${H_01};
    }
  }
`;

const FirstSectionVideo = styled.video`
  object-fit: cover;
  pointer-events: none;
  .isMobile && {
    position: relative;
    width: 100%;
    height: 210px;
  }

  .isTablet && {
    position: relative;
    width: 100%;
    height: 250px;
  }

  .isDesktop && {
    position: relative;
    width: 100%;
    height: 720px;
  }
`;

const SectionTitle = styled.p`
  color: ${palette.neutralNormal};
  text-shadow: 0px 0px 1px ${palette.opacityB25};
  text-align: center;
  .isMobile &&,
  .isTablet && {
    ${H_04};
  }
  .isDesktop && {
    ${H_01};
  }
`;

const Button = styled.button<{ width?: string }>`
  width: ${(props) => props.width || "100%"};
  padding: 18px 32px;
  border-radius: 4px;
  background-color: black;
  color: white;
  ${Text_02};
  margin-top: 18px;

  .isTablet && {
    margin: 30px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 80px);
  }

  .isDesktop && {
    width: 127px;
    margin-bottom: 78px;
  }
`;

const ImageCarousel = styled.div`
  .isMobile && {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    margin: 50px 0 0 0;
    @media (min-width: 428px) and (max-width: 767px) {
      padding: 0 30px;
    }
  }
  .isTablet &&,
  .isDesktop && {
    /* margin-top: 78px; */
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
`;

const ImageItem = styled.img`
  .isMobile && {
    width: 100%;
    height: 216px;
    object-fit: cover;
    object-position: left;
    cursor: pointer;
  }

  .isTablet && {
    width: 368px;
    height: 216px;
    object-fit: cover;
    flex-shrink: 0;
    cursor: pointer;
    &:last-of-type {
      margin-right: 12px;
    }
  }
  .isDesktop && {
    width: 620px;
    height: 360px;
    object-fit: cover;
    flex-shrink: 0;
    cursor: pointer;
    &:last-of-type {
      margin-right: 12px;
    }
  }
`;

export default WelcomeContainer;
