import { css } from "styled-components";

const H_01 = css`
  font-size: 72px;
  font-weight: 700;
  line-height: 100px;
`;

const H_02 = css`
  font-size: 60px;
  font-weight: 700;
  line-height: 96px;
`;

const H_03 = css`
  font-size: 48px;
  font-weight: 700;
  line-height: 76px;
`;

const H_04 = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
`;

const Sub_01 = css`
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
`;

const Sub_02 = css`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
`;

const Sub_14 = css`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const Sub_16_B = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const Text_01 = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
`;

const Text_02 = css`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const Caption_13_B = css`
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
`;

const Caption_13_R = css`
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`;

const Title_20 = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

const Title_24_B = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
`;

const Body_02 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
`;

const Body_16 = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
`;

export {
  H_01,
  H_02,
  H_03,
  H_04,
  Sub_01,
  Sub_02,
  Sub_14,
  Sub_16_B,
  Text_01,
  Text_02,
  Caption_13_B,
  Caption_13_R,
  Title_20,
  Title_24_B,
  Body_02,
  Body_16,
};
