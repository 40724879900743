import device from "modules/defines/device";
import { palette } from "modules/defines/styles";
import styled, { css } from "styled-components";

const IconAsset = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6913 16L9.33331 4.96L10.321 4L22.6666 16L10.321 28L9.33331 27.04L20.6913 16Z"
        fill="#70747C"
      />
    </svg>
  );
};

const getChevronButtonPosition = (positionType: "left" | "right" | "bottom") => {
  switch (positionType) {
    case "left":
      return css`
        .isMobile &&,
        .isDesktop {
          display: none;
        }
        rotate: 180deg;
        top: 270px;
        transform: translate(-30%);
        left: -40px;
      `;
    case "right":
      return css`
        .isMobile &&,
        .isDesktop {
          display: none;
        }
        rotate: 0deg;
        top: 270px;
        transform: translate(-30%);
        right: -40px;
      `;
    case "bottom":
      return css`
        rotate: 90deg;
        .isMobile &&,
        .isTablet && {
          left: 45%;
          bottom: -120px;
        }
        .isDesktop && {
          left: 50%;
          transform: translate(-50%);
          bottom: -120px;
          ${device.largeDesktop} {
            left: 50%;
            transform: translate(-50%);
            bottom: -160px;
          }
        }
      `;
    default:
      return null;
  }
};

interface ChevronButtonProps {
  onClick?: () => void | ((e: MouseEvent) => void);
  positionType: "left" | "right" | "bottom";
}

const ChevronButton: React.FC<ChevronButtonProps> = ({ positionType, onClick }) => {
  return (
    <ButtonWrapper type="button" onClick={onClick} positionType={positionType}>
      <IconAsset />
    </ButtonWrapper>
  );
};

export default ChevronButton;

const ButtonWrapper = styled.button<{
  positionType: "left" | "right" | "bottom";
}>`
  ${({ positionType }) => getChevronButtonPosition(positionType)};
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  z-index: 10;
  border: 1px solid ${palette.neutral04};
  background: #fff;
`;
