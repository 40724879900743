import { Body_16, H_03, Sub_16_B, Text_01, Text_02 } from "modules/style/typography";
import styled from "styled-components";
import { ButtonSolid } from "./common/Button";
import { map, map2 } from "assets/img";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);
const Contact = () => {
  const listRef = useRef<HTMLLIElement[] | null[]>([null]);
  const mapRef = useRef<HTMLElement[] | null[]>([null]);
  const test = useRef<HTMLDivElement | null>(null);
  useGSAP(() => {
    gsap.from(listRef.current, {
      opacity: 0,
      delay: 0.001,
      duration: 2,
      y: -20,
      stagger: 0.23,
      scrollTrigger: {
        trigger: test.current,
        start: "top center",
        end: "bottom center",
      },
    });

    gsap.from(mapRef.current, {
      opacity: 0,
      delay: 0.2,
      duration: 2,
      x: -20,
      stagger: 0.5,
      scrollTrigger: {
        trigger: test.current,
        start: "top center",
        end: "bottom center",
      },
    });
  }, []);

  return (
    <ContactSection padding="120px 0px" ref={test}>
      <List>
        <Title ref={(el) => (listRef.current[0] = el)}>CONTACT</Title>
        <ListItem ref={(el) => (listRef.current[1] = el)}>
          <ListItemTitle>고객 문의</ListItemTitle>
          <ListItemContent isDecoration>cs@favapp.io</ListItemContent>
        </ListItem>
        <ListItem ref={(el) => (listRef.current[2] = el)}>
          <ListItemTitle>제휴 문의</ListItemTitle>
          <ListItemContent isDecoration>support@favapp.io</ListItemContent>
        </ListItem>
        <ListItem ref={(el) => (listRef.current[3] = el)}>
          <ListItemTitle>채용 문의</ListItemTitle>
          <ListItemContent isDecoration>paeng@broz.co.kr</ListItemContent>
        </ListItem>
        <ListItem ref={(el) => (listRef.current[4] = el)}>
          <ListItemTitle>FAX</ListItemTitle>
          <ListItemContent aria-roledescription="fax" isDecoration={false}>
            02-6280-9596
          </ListItemContent>
        </ListItem>
        <ListItem ref={(el) => (listRef.current[5] = el)}>
          <ButtonSolid
            type="button"
            onClick={() => window.open("https://pf.kakao.com/_KxgwWxj", "_blank")}
            marginTop="40px"
            mode="dark"
            size="M"
          >
            카카오톡 문의
          </ButtonSolid>
        </ListItem>
      </List>
      <AddressContainer>
        <AddressWrapper ref={(el) => (mapRef.current[0] = el)}>
          <Map src={map} alt="" />
          <Figcaption>
            <AddressTitle>Broz 본사</AddressTitle>
            <Address>대구광역시 동구 동대구로 465, 503호 (5F)</Address>
          </Figcaption>
        </AddressWrapper>

        <AddressWrapper ref={(el) => (mapRef.current[1] = el)}>
          <Map src={map2} alt="" />
          <Figcaption>
            <AddressTitle>기업부설연구소</AddressTitle>
            <Address>서울특별시 마포구 동교로 142 (3F)</Address>
          </Figcaption>
        </AddressWrapper>
      </AddressContainer>
    </ContactSection>
  );
};

const LayoutSection = styled.section<{ padding: string }>`
  padding: ${({ padding }) => padding};
`;

const ContactSection = styled(LayoutSection)`
  background: #000;
  .isDesktop && {
    display: grid;
    grid-template-columns: 600px 1fr;
    padding: 120px 100px;
    @media (min-width: 1920px) {
      padding: 120px 340px;
    }
  }
`;

const AddressContainer = styled.div`
  display: grid;
  row-gap: 40px;
`;

const AddressWrapper = styled.figure`
  display: flex;
  flex-direction: column;
  .isMobile && {
    gap: 12px;
    padding: 0 20px;
    @media (min-width: 428px) and(max-width: 768px) {
      padding: 0 40px;
      gap: 12px;
    }
  }

  .isTablet &&,
  .isDesktop && {
    gap: 12px;
    padding: 0 40px;
  }
`;

const Figcaption = styled.figcaption`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Map = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  .isDesktop && {
    min-width: 600px;
    height: 240px;
  }
  /* padding: 20px; */
`;

const AddressTitle = styled.div`
  ${Text_01};
`;

const Address = styled.address`
  ${Body_16};
`;

const List = styled.ul`
  max-width: 600px;
  display: grid;
  height: fit-content;
  row-gap: 16px;

  background: #000;

  .isMobile && {
    padding: 0 20px 120px 20px;
    @media (min-width: 428px) and(max-width: 768px) {
      padding: 0 40px 120px 40px;
    }
  }

  .isTablet && {
    padding: 0 40px 120px 40px;
  }
  .isDesktop && {
    padding: 0;
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 40px;
  color: white;
`;

const ListItemTitle = styled.span`
  ${Text_02};
  min-width: 70px;
  height: 24px;
`;

const Title = styled.li`
  color: white;

  margin-bottom: 80px;
  .isMobile && {
    ${Text_01};
    @media (min-width: 428px) and (max-width: 768px) {
      ${H_03};
    }
  }
  .isTablet &&,
  .isDesktop && {
    ${H_03};
  }
`;

const ListItemContent = styled.span<{
  isDecoration: boolean;
}>`
  ${Sub_16_B};
  text-decoration: ${(props) => (props.isDecoration ? "underline" : "none")};
  text-underline-position: under;
  height: 24px;
`;

export default Contact;
