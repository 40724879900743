import { palette } from "modules/defines/styles";
import { Body_16, H_03, Sub_01, Text_02 } from "modules/style/typography";
import { useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
interface ProcessCardProps {
  number: string;
  title: string;
  title2?: string;
  descriptionSection1: string;
  descriptionSection2: string;
  descriptionSection3: string;
  isEmComment?: boolean;
  emComment?: string;
}
const ProcessCard: React.FC<ProcessCardProps> = ({ ...props }) => {
  const {
    number,
    title,
    title2,
    descriptionSection1,
    descriptionSection2,
    descriptionSection3,
    isEmComment,
    emComment,
  } = props;

  gsap.registerPlugin(useGSAP);
  const ref = useRef<HTMLUListElement>(null);
  useGSAP(() => {
    gsap.to(ref.current, {
      y: 0,
      opacity: 1,
    });
  });
  return (
    <ProcessCardList ref={ref}>
      <ProcessListItem>
        <Number>{number}</Number>
        <ProcessCardTitle>
          {title}
          <br />
          {title2}
        </ProcessCardTitle>
      </ProcessListItem>
      <ProcessListItem>
        <ProcessCardDescription> {descriptionSection1} </ProcessCardDescription>
        <ProcessCardDescription> {descriptionSection2}</ProcessCardDescription>
        <ProcessCardDescription> {descriptionSection3}</ProcessCardDescription>
        {isEmComment ? <EmComment>{emComment}</EmComment> : null}
      </ProcessListItem>
    </ProcessCardList>
  );
};

const Number = styled.div`
  ${H_03};
  color: white;
`;
const ProcessCardDescription = styled.div`
  ${Body_16};
  color: white;
`;

const EmComment = styled.em`
  ${Text_02};
  color: ${palette.primaryNormal};
  margin-top: 20px;
`;

const ProcessCardTitle = styled.div`
  ${Sub_01};
  color: white;
  white-space: pre-wrap;
`;
const ProcessListItem = styled.li`
  display: flex;
  flex-direction: column;
`;

const ProcessCardList = styled.ul`
  opacity: 0;
  transform: translateY(-20px);
  border-radius: 20px;
  border: 1px solid ${palette.neutral02};
  width: 320px;
  height: 480px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 40px 30px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(50px);
`;

export default ProcessCard;
