import { useEffect } from "react";
import WelcomeContainer from "containers/WelcomeContainer";
import MainContainer from "containers/MainContainer";
import { pagePaths } from "modules/defines/path";
import { Route, Switch, useLocation } from "react-router-dom";
import ListContainer from "containers/ListContainer";
import IframeContainer from "containers/IframeContainer";

// import ChannelService from "ChannelService";
const useChatbotVisibility = () => {
  const location = useLocation();

  useEffect(() => {
    document.oncontextmenu = function () {
      return false;
    };
    const allowedPaths = [pagePaths.main, pagePaths.welcome, pagePaths.list];
    const chatbotElement = document.getElementById("ch-plugin");

    const setChatbotVisibility = () => {
      if (allowedPaths.includes(location.pathname)) {
        chatbotElement?.setAttribute("style", "display: block !important");
      } else {
        chatbotElement?.setAttribute("style", "display: none !important");
      }
    };

    if (chatbotElement) {
      setChatbotVisibility();
    } else {
      const observer = new MutationObserver(() => {
        const newChatbotElement = document.getElementById("ch-plugin");
        if (newChatbotElement) {
          setChatbotVisibility();
          observer.disconnect();
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    }
  }, [location.pathname]);
};

const RouterComponent = () => {
  useChatbotVisibility();

  return (
    <Switch>
      <Route path={pagePaths.main} exact component={MainContainer} />
      <Route path={pagePaths.welcome} exact component={WelcomeContainer} />
      <Route path={pagePaths.list} exact component={ListContainer} />
      <Route path="*" component={IframeContainer} />
    </Switch>
  );
};

export default RouterComponent;
