import { css } from "styled-components";
import device from "modules/defines/device";

export const palette = {
  white: "#FFFFFF",
  black: "#000000",

  opacityB20: "rgba(0, 0, 0, 0.20)",
  opacityB25: "rgba(0, 0, 0, 0.25)",
  opacityB40: "rgba(0, 0, 0, 0.40)",
  opacityB60: "rgba(0, 0, 0,  0.60)",
  opacityB80: "rgba(0, 0, 0, 0.80)",

  neutral100: "#F8F9FC",
  neutral200: "#F1F3F9",
  neutral300: "#E1E6EF",
  neutral400: "#9CA5B8",
  neutral500: "#717784",
  neutral600: "#585E6A",
  neutral700: "#3F444D",
  neutral800: "#23272F",
  neutral900: "#1B1F27",
  neutral1000: "#1E1E1E",
  neutralNormal: "#1A1A1A",

  neutral01: "#3D4043",
  neutral02: "rgba(112, 116, 124, 1)",
  neutral03: "#B8B8B8",
  neutral04: "#EBEBEB",
  neutral05: "#F3F4F6",

  imageCoverBlack: "rgba(50, 50, 50, 0.40)",
  background: "#2B2B2B",

  primary900: "#2B2B2B",
  primaryNormal: "#0097FF",
  // Not using, for Testing
  testRed: "#FF0000",
};

export const LayoutPadding = css`
  .isMobile && {
    padding: 0 20px;
    ${device.mobile428} {
      padding: 0 30px;
    }
  }

  .isTablet && {
    /* padding: 0 40px; */
  }
`;
