import Footer from "components/Layout/Footer";
import Header from "components/Layout/Header";
import IntroduceProduct from "composite/List/IntroduceProduct";
import Title from "composite/List/Title";
import styled from "styled-components";

import CategoryProduct from "composite/List/CategoryProduct";
import device from "modules/defines/device";
import { useLayoutEffect, useState } from "react";
import { spaceAPI } from "api";
import { SpaceInfoProps } from "modules/defines/interface";

const ListContainer = () => {
  const [data, setData] = useState<SpaceInfoProps[] | null>(null);

  async function fetchSpaceData() {
    await spaceAPI
      .getAllSpace()
      .then((res) => {
        const convertImg = res.data.space
          .filter((item: any) => item.publish_faview)
          .map((item: any) => ({
            ...item,
            img: item.img?.replace(/.jpg|.jpeg|.png/, "_500.webp"),
          }));
        setData(convertImg);
      })

      .catch((err) => {
        alert(`${err}:데이터 불러오기에 실패했습니다.`);
      });
  }

  useLayoutEffect(() => {
    fetchSpaceData();
  }, []);

  return (
    <ListContainerWrapper>
      <Header />
      <Main>
        <Title />
        <IntroduceProduct data={data} />
        <CategoryProduct data={data} />
      </Main>
      <Footer mode="light" />
    </ListContainerWrapper>
  );
};

const ListContainerWrapper = styled.div`
  background-color: white;
  overflow-x: hidden;
`;

const Main = styled.main`
  .isMobile && {
    padding: calc(48px + 70px) 0px 170px 0;
  }

  .isTablet && {
    padding: calc(48px + 70px) 40px 170px 40px;
  }
  .isDesktop && {
    position: relative;
    max-width: 1240px;
    margin: 0 auto;
    padding: calc(100px + 70px) 40px;
    ${device.largeDesktop} {
      padding: calc(100px + 70px) 0px 210px 0px;
    }
  }
`;

export default ListContainer;
