import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Layout = styled.div<{ padding: string }>`
  padding: ${({ padding }) => padding};
`;
