import { bronte } from "assets/img";
import ChevronButton from "components/common/ChevronButton";
import device from "modules/defines/device";
import { LayoutPadding, palette } from "modules/defines/styles";
import { Text_01, Sub_02, Sub_01, Text_02, Body_02, Body_16 } from "modules/style/typography";
import { useLayoutEffect, useRef, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { spaceAPI } from "api";
import { GetSpaceApiProps, SpaceInfoProps, SpaceProps } from "modules/defines/interface";
import { no_img_lg } from "assets/img";

gsap.registerPlugin(useGSAP);

interface CategoryProductProps {
  data: SpaceInfoProps[] | null;
}

const CategoryProduct: React.FC<CategoryProductProps> = ({ data }) => {
  const CATEGORY = ["전체", "음식점", "카페", "문화공간", "숙박", "기타", "직접촬영", "전문촬영"];

  const convertCategoryKor = (category: string) => {
    switch (category) {
      case "0":
      case "2":
        return "음식점";
      case "1":
        return "카페";
      case "3":
        return "문화공간";
      case "4":
        return "숙박";
      case "5":
      case "6":
      default:
        return "기타";
    }
  };

  const [page, setPage] = useState(1);
  const [category, setCategory] = useState(CATEGORY[0]);
  const containerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLUListElement>(null);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [isDown, setIsDown] = useState(false);

  const startSwipe = (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    setIsDown(true);
    const x = "touches" in e ? e.touches[0].pageX : e.pageX;
    setStartX(x);
    if (containerRef.current) {
      setScrollLeft(containerRef.current.scrollLeft);
    }

    if (window.innerWidth >= 1920) {
      const y = "touches" in e ? e.touches[0].pageY : e.pageY;
      setStartY(y);
      if (containerRef.current) {
        setScrollTop(containerRef.current.scrollTop);
      }
    }
  };
  const moveSwipe = (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    if (!isDown) return;
    e.preventDefault();
    const x = "touches" in e ? e.touches[0].pageX : e.pageX;
    const walk = (startX - x) * 2;
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft + walk;
    }

    if (window.innerWidth >= 1920) {
      const y = "touches" in e ? e.touches[0].pageY : e.pageY;
      const walk = (startY - y) * 2;
      if (containerRef.current) {
        containerRef.current.scrollTop = scrollTop + walk;
      }
    }
  };
  const endSwipe = () => {
    setIsDown(false);
  };

  const filterDateLength =
    data &&
    data.filter((item: { category: string; isLight: boolean }) => {
      if (category === "전체") {
        return true;
      } else if (category === "직접촬영") {
        return item.isLight;
      } else if (category === "전문촬영") {
        return !item.isLight;
      } else {
        return convertCategoryKor(item.category?.split("/")[0]) === category;
      }
    }).length;
  const shuffle = (array: SpaceInfoProps[] | null) => {
    if (array) return array.sort(() => Math.random() - 0.5);
  };
  return (
    <Fragment>
      <CategoryListWrapper
        ref={containerRef}
        onMouseDown={startSwipe}
        onMouseMove={moveSwipe}
        onMouseUp={endSwipe}
        onMouseLeave={endSwipe}
        onTouchStart={startSwipe}
        onTouchMove={moveSwipe}
        onTouchEnd={endSwipe}
      >
        <CategoryList>
          {CATEGORY.map((item, index) => {
            const handleCategoryItem = () => {
              setCategory(item);
              setPage(1);
              shuffle(data);
              if (category !== item) {
                gsap.set(wrapperRef.current, {
                  opacity: 0,
                  y: -20,
                });
                gsap.to(wrapperRef.current, {
                  opacity: 1,
                  y: 0,
                  duration: 0.5,
                });
              }
            };
            return (
              <CategoryListItem
                tabIndex={0}
                key={index}
                onClick={handleCategoryItem}
                className={category === item ? "active" : ""}
              >
                {item}
              </CategoryListItem>
            );
          }).slice(0, 6)}
        </CategoryList>
        <CategoryDivide>/</CategoryDivide>
        <CategoryList>
          {CATEGORY.map((item, index) => {
            const handleCategoryItem = () => {
              setCategory(item);
              setPage(1);
              shuffle(data);
              if (category !== item) {
                gsap.set(wrapperRef.current, {
                  opacity: 0,
                  y: -20,
                });
                gsap.to(wrapperRef.current, {
                  opacity: 1,
                  y: 0,
                  duration: 0.5,
                });
              }
            };
            return (
              <CategoryListItem
                tabIndex={0}
                key={index}
                onClick={handleCategoryItem}
                className={category === item ? "active" : ""}
              >
                {item}
              </CategoryListItem>
            );
          }).slice(-2, 8)}
        </CategoryList>
      </CategoryListWrapper>
      <CategoryProductWrapper ref={wrapperRef}>
        {data &&
          data
            .filter((item: { category: string; isLight: boolean }) => {
              if (category === "전체") {
                return true;
              } else if (category === "직접촬영") {
                return item.isLight;
              } else if (category === "전문촬영") {
                return !item.isLight;
              } else {
                return convertCategoryKor(item.category?.split("/")[0]) === category;
              }
            })
            .flatMap((item: any) => {
              return (
                <li key={item.id} onClick={() => window.open(`https://faview.io/view/${item.id}`)}>
                  <CategoryProductItem>
                    <CategoryProductImg
                      onError={(e) => {
                        e.currentTarget.src = no_img_lg;
                      }}
                      src={item.img !== undefined ? item.img : no_img_lg}
                      alt=""
                    />
                    <CategoryProductContent>
                      <CategoryProductTitle>{item.title}</CategoryProductTitle>
                      <CategoryProductCategoryWrapper>
                        <CategoryProductCategory className="withDivider">
                          {convertCategoryKor(item.category?.split("/")[0])}
                        </CategoryProductCategory>
                        <CategoryProductCategory>
                          {item.isLight ? CATEGORY[6] : CATEGORY[7]}
                        </CategoryProductCategory>
                      </CategoryProductCategoryWrapper>
                    </CategoryProductContent>
                  </CategoryProductItem>
                </li>
              );
            })
            .slice(0, 6 * page)}
        {filterDateLength && filterDateLength > 6 * page && (
          <ChevronButton
            onClick={() => {
              setPage((pre) => pre + 1);
            }}
            positionType="bottom"
          />
        )}
      </CategoryProductWrapper>
    </Fragment>
  );
};

const CategoryProductWrapper = styled.ul`
  ${LayoutPadding};
  display: grid;
  grid-template-columns: repeat(3, 380px);
  column-gap: 10px;
  row-gap: 45px;
  position: relative;
  .isMobile && {
    grid-template-columns: repeat(1, 320px);
    column-gap: 0px;
    row-gap: 32px;
    justify-content: center;
    ${device.mobile428} {
      grid-template-columns: repeat(1, 368px);
      column-gap: 10px;
      row-gap: 32px;
    }
  }
  .isTablet && {
    grid-template-columns: repeat(2, 339px);
    column-gap: 10px;
    row-gap: 45px;
    justify-content: center;
  }
  .isDesktop && {
    grid-template-columns: repeat(3, 380px);
    column-gap: 20px;
    row-gap: 40px;
    /* justify-content: center; */
    ${device.largeDesktop} {
      grid-template-columns: repeat(3, 400px);
      column-gap: 20px;
      row-gap: 40px;
    }
  }
`;
const CategoryProductItem = styled.figure`
  cursor: pointer;
  &:hover {
    scale: 1.05;
    transition: scale 0.2s ease-in-out;
  }
  display: grid;
  row-gap: 16px;
`;

const CategoryProductContent = styled.div`
  display: grid;
  row-gap: 8px;
`;

const CategoryProductImg = styled.img`
  max-width: 400px;
  object-fit: cover;
  .isMobile && {
    width: 320px;
    height: 225px;
    ${device.mobile428} {
      width: 368px;
      height: 225px;
    }
  }
  .isTablet && {
    width: 339px;
    height: 225px;
  }
  .isDesktop && {
    width: 380px;
    height: 250px;
    ${device.largeDesktop} {
      width: 400px;
      height: 250px;
    }
  }
`;

const CategoryProductTitle = styled.div`
  .isMobile && {
    ${Text_02};
  }
  ${Text_01};
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-width: 400px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const CategoryProductCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CategoryProductCategory = styled.div`
  .isMobile && {
    ${Body_16};
  }
  ${Sub_02};
  color: #000;

  &.withDivider {
    display: flex;
    align-items: center;
    gap: 8px;
    &:after {
      display: block;
      content: "";
      width: 1px;
      height: 16px;
      margin-right: 8px;
      background-color: #000;
    }
  }
`;

const CategoryListWrapper = styled.div`
  display: flex;
  gap: 60px;
  margin-bottom: 60px;
  align-items: center;

  ::-webkit-scrollbar {
    display: none;
  }

  .isMobile && {
    margin-left: 20px;
    margin-right: 30px;
    margin-bottom: 40px;
    overflow: scroll;
    gap: 28px;
    ${device.mobile428} {
      margin-left: 40px;
      margin-right: 37px;
      margin-bottom: 40px;
      overflow: scroll;
      gap: 40px;
    }
  }

  .isTablet && {
    gap: 60px;
    overflow: scroll;
  }
`;

const CategoryList = styled.ul`
  display: flex;
  gap: 60px;
  .isMobile && {
    gap: 28px;
    ${device.mobile428} {
      gap: 40px;
    }
  }
  .isTablet && {
    gap: 60px;
  }
`;

const CategoryDivide = styled.div`
  padding: 12px 0;
  color: ${palette.neutral03};
  ${Sub_01};
  .isMobile && {
    ${Sub_02};
  }
`;

const CategoryListItem = styled.li`
  .isMobile && {
    ${Sub_02};
    padding: 6px 0;
    white-space: nowrap;
  }
  .isTablet &&,
  .isDesktop && {
    ${Sub_01};
    padding: 12px 0;
    white-space: nowrap;
  }

  color: ${palette.neutral03};
  cursor: pointer;
  &:hover {
    transition: color 0.1s ease-in-out;
    color: ${palette.neutralNormal};
  }
  &.active {
    pointer-events: none;
    cursor: default;
    color: ${palette.neutralNormal};
  }
`;

export default CategoryProduct;
