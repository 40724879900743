import { BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import { UserAgent } from "utils/userAgent";
import RouterComponent from "router/router";
import GlobalStyles from "GlobalStyles";
import { GlobalFontStyle } from "modules/defines/fonts";
import { Helmet } from "react-helmet";

function App() {
  const { os } = UserAgent;
  const isTablet = () => {
    const userAgent = navigator.userAgent;

    if (
      /iPad/.test(userAgent) ||
      (/Macintosh/.test(userAgent) && "ontouchend" in document)
    ) {
      return true;
    }

    if (/Android/.test(userAgent) && !/Mobile/.test(userAgent)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (os.isIos || os.isAndroid) {
      if (isTablet()) {
        document.body.classList.add("isTablet");
      } else {
        document.body.classList.add("isMobile");
      }
    } else {
      document.body.classList.add("isDesktop");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BrowserRouter>
      <Helmet>
        <link
          rel="preload"
          href="/fonts/my-font.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Helmet>
      <GlobalStyles />
      <GlobalFontStyle />
      <RouterComponent />
    </BrowserRouter>
  );
}

export default App;
