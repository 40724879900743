import { palette } from "modules/defines/styles";
import { Caption_13_B, Caption_13_R, Sub_14 } from "modules/style/typography";
import styled from "styled-components";
import { icon_instar } from "assets/icon";
import device from "modules/defines/device";
import { Link } from "react-router-dom";
interface FooterProps {
  mode: string;
}
const Footer = ({ mode }: FooterProps) => {
  return (
    <FooterContainer mode={mode}>
      <IconWrapper onClick={() => window.open("https://www.instagram.com/favkorea/")}>
        <img src={icon_instar} alt="instagram" />
      </IconWrapper>
      <Title>(주) 브로즈</Title>
      <FooterList>
        <FooterListItem>
          <span>대표</span>
          <span>김찬일, 김승윤</span>
        </FooterListItem>
        <FooterListItem>
          <span>사업자 등록 번호</span>
          <span>288-81-02136</span>
        </FooterListItem>
        <FooterListItem>
          <span>본사</span>
          <address>대구광역시 동구 동대구로 465, 5층 503호</address>
        </FooterListItem>
        <FooterListItem>
          <span>기업부설연구소</span>
          <address>서울 마포구 동교로 142 3층</address>
        </FooterListItem>
        <FooterListItem>
          <span>고객센터</span>
          <CSLinkWrapper>
            <CSLinkItem hrefLang="ko-KR" href="tel:1551-8802">
              1551-8802
            </CSLinkItem>
            |
            <CSLinkItem type="email" href="mailto:cs@faview.ai">
              cs@faview.ai
            </CSLinkItem>
          </CSLinkWrapper>
        </FooterListItem>

        <CopyLight mode={mode}>&copy; Broz Corp. All rights reserve</CopyLight>
      </FooterList>
      <Line />
      <SubFooterList>
        <SubFooterListItem>
          <a href="/">사업자정보 확인</a>
        </SubFooterListItem>
        <SubFooterListItem isImportant>
          <a href="/">개인정보처리방침</a>
        </SubFooterListItem>
        <SubFooterListItem>
          <a href="/">이용약관</a>
        </SubFooterListItem>
      </SubFooterList>
    </FooterContainer>
  );
};
export default Footer;

const FooterContainer = styled.footer<{ mode: string }>`
  /* ${palette.neutralNormal}; */
  position: relative;
  background-color: ${(props) => (props.mode === "dark" ? palette.black : palette.neutralNormal)};
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  .isMobile && {
    /* background-color: red; */
    padding: 20px;

    ${device.mobile428} {
      padding: 20px 40px;
    }
  }

  .isTablet && {
    /* background-color: blue; */
    padding: 20px 40px;
  }

  .isDesktop && {
    /* background-color: green; */
    padding: 20px 40px;
    ${device.largeDesktop} {
      padding: 20px 340px;
    }
  }
`;

const Title = styled.div`
  color: white;
  ${Sub_14};
  margin-bottom: 12px;
`;

const FooterList = styled.ul``;

const SubFooterList = styled(FooterList)`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 12px;
`;

const SubFooterListItem = styled.li<{ isImportant?: boolean }>`
  ${Caption_13_B};
  color: ${(props) => (props.isImportant ? palette.primaryNormal : palette.neutral03)};
  padding: 2px 0;
`;

const Line = styled.hr`
  margin-top: 20px;
`;
const CopyLight = styled.p<{ mode: string }>`
  color: ${(props) => (props.mode === "dark" ? palette.neutral05 : palette.white)};

  ${Caption_13_R};
`;

const FooterListItem = styled.li`
  display: grid;
  column-gap: 6px;
  grid-template-columns: fit-content(100%) 2fr;
  & > :first-child {
    ${Caption_13_B};
    color: ${palette.neutral03};
  }

  & > :last-child {
    ${Caption_13_R};
    color: ${palette.neutral03};
  }
`;

const CSLinkWrapper = styled.div`
  ${Caption_13_R};
  color: ${palette.neutral03};
  display: flex;
  gap: 6px;
  align-items: center;
`;

const CSLinkItem = styled.a`
  ${Caption_13_R};
  color: ${palette.neutral03};
`;

const IconWrapper = styled.div`
  width: fit-content;
  position: absolute;

  padding: 10px;
  border-radius: 100%;
  background-color: ${palette.neutral02};
  cursor: pointer;

  .isMobile && {
    top: 20 px;
    right: 20px;
    ${device.mobile428} {
      top: 20px;
      right: 40px;
    }
  }

  .isTablet && {
    top: 20px;
    right: 40px;
  }

  .isDesktop && {
    top: 20px;
    right: 40px;
    ${device.largeDesktop} {
      top: 20px;
      right: 340px;
    }
  }
`;
