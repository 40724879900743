import { palette } from "modules/defines/styles";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle` 

  ${reset}

  body::-webkit-scrollbar {
	    display: none;
    }


    a{
        text-decoration: none;
        color: inherit;
    }
    *{
        box-sizing: border-box;
    }

    body{
        background-color: #000;
        overflow-x: hidden;
        touch-action: pan-y;
   
    }

 body:focus-visible {
    /* Remove Default Focus Style */
    outline: none;
    /* Custon Focus Styles */
    outline-color: ${palette.primaryNormal};
    outline-style: solid;
    outline-width: 2px;
    border-radius: 12px;
  }

    #root{
    max-width: 1920px;
    margin: 0 auto;
    }
    video {
    margin: 0;
  padding: 0;
  border: none;
}
    html, body, div, span, h1, h2, h3, h4, h5, h6, p, 
    a, dl, dt, dd, ol, ul, li, form, label, table{
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 10px;
        vertical-align: baseline;
        font-family : Pretendard;
       -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
    }
    input, textarea { 
        -moz-user-select: auto;
        -webkit-user-select: auto;
        -ms-user-select: auto;
        user-select: auto;
    }
    input:focus {
        outline: none;

    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
	    -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${palette.neutral500};
    }

    input:autofill,
    input:autofill:hover,
    input:autofill:focus,
    input:autofill:active {
	    -webkit-text-fill-color: #000;
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: ${palette.neutral500};
    }

    button {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;
    }

    .line-clamp {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}



 
`;

export default GlobalStyles;
