export const pagePaths = {
  main: "/",
  welcome: "/welcome",
  list: "/list",
};

export const apiPaths = {
  awsBaseUrl: "https://path.faview.ai/",
  awsLocalUrl: "http://localhost:5030/",
  getAllSpace: "faview/space/all/",
};
