import styled from "styled-components";

export const SwiperContainer = styled.div`
  margin: 0 auto;
  .isMobile && {
    padding: 20px 0 0 20px;
    @media (min-width: 428px) and (max-width: 767px) {
      padding: 0px 0px 0px 34px;
    }
  }

  .isTablet && {
    padding: 0;
    max-width: 960px;
  }
  .isDesktop && {
    padding: 0;
    width: fit-content;
    max-width: 1000px;
  }
`;
