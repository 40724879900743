import { bg_dark, bg_light, list_img_1 } from "assets/img";
import ChevronButton from "components/common/ChevronButton";
import device from "modules/defines/device";
import { SpaceInfoProps } from "modules/defines/interface";
import { LayoutPadding, palette } from "modules/defines/styles";
import {
  Text_01,
  H_04,
  Text_02,
  Sub_02,
  Body_02,
  Caption_13_B,
  Body_16,
} from "modules/style/typography";
import { useRef, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import styled from "styled-components";

const ITEM_UPLOAD_EX = [3210, 3016, 3047, 3059, 3222, 3035];
const ITEM_REQUEST_EX = [520, 1760, 501, 1386, 445, 1599, 1281, 1081];

type IntroduceProductProps = "inPerson" | "professional";

interface IntroduceProductListItemProps {
  isButton?: boolean;
  onClick?: () => void;
  category: IntroduceProductProps;
}
const IntroduceProductListItem: React.FC<IntroduceProductListItemProps> = ({ category }) => {
  return (
    <IntroductionImageDescriptionWrapper>
      <IntroductionImageDescription category={category} className="title">
        <IntroductionImageDescriptionTitle>
          {category === "inPerson" ? "직접촬영" : "전문촬영"} 제작 안내
        </IntroductionImageDescriptionTitle>
        <IntroductionImageDescriptionContent>
          {category === "inPerson"
            ? "스마트폰 동영상으로 360도 공간 뷰어 제작"
            : "Pro insta 360(전문 카메라)로 촬영에서 제작까지"}
        </IntroductionImageDescriptionContent>
      </IntroductionImageDescription>
      <IntroductionImageDescription>
        <IntroductionNumber>01</IntroductionNumber>
        <IntroductionNumberTitle category={category}>
          {category === "inPerson" ? "공간 영상 촬영" : `촬영 문의\n 및 견적서 작성`}
        </IntroductionNumberTitle>
        <IntroductionNumberButton
          category={category}
          type="button"
          onClick={
            category === "inPerson"
              ? () =>
                  window.open(
                    "https://broz.notion.site/FAVIEW-Light-cd0a3d703bf94af993c4feab4fbd4dea",
                    "_blank"
                  )
              : () =>
                  window.open(
                    "https://broz.notion.site/FAVIEW-Light-cd0a3d703bf94af993c4feab4fbd4dea",
                    "_blank"
                  )
          }
        >
          {category === "inPerson" ? "  촬영 가이드 보기" : "문의하기"}
        </IntroductionNumberButton>
      </IntroductionImageDescription>
      <IntroductionImageDescription>
        <IntroductionNumber>02</IntroductionNumber>
        <IntroductionNumberTitle category={category}>
          {category === "inPerson" ? "영상 업로드" : "공간 방문 및 촬영"}
        </IntroductionNumberTitle>
        <IntroductionNumberButton
          onClick={() => window.open("https://faview.ai/portal/mypage", "_blank")}
          category={category}
          type="button"
        >
          {category === "inPerson" ? "무료로 제작하기" : ""}
        </IntroductionNumberButton>
      </IntroductionImageDescription>
      <IntroductionImageDescription>
        <IntroductionNumber>03</IntroductionNumber>
        <IntroductionNumberTitle category={category}>3D 공간뷰어 제작 완료</IntroductionNumberTitle>
      </IntroductionImageDescription>
    </IntroductionImageDescriptionWrapper>
  );
};

interface Props {
  data: SpaceInfoProps[] | null;
}

const IntroduceProduct: React.FC<Props> = ({ data }) => {
  const [activeCategory, setActiveCategory] = useState<IntroduceProductProps>("inPerson");
  const [itemList, setItemList] = useState<number[]>(ITEM_UPLOAD_EX);
  const listRef = useRef<HTMLDivElement>(null);

  const handleScroll = (direction: "left" | "right") => {
    if (listRef.current) {
      listRef.current.scrollTo({
        left:
          direction === "left"
            ? listRef.current.scrollLeft - 600
            : listRef.current.scrollLeft + 600,
        behavior: "smooth",
      });
    }
  };

  return (
    <Fragment>
      <IntroductionImageCategoryList>
        <ChevronButton onClick={() => handleScroll("left")} positionType="left" />
        <ChevronButton onClick={() => handleScroll("right")} positionType="right" />
        <IntroductionCategoryListItem
          className={activeCategory === "inPerson" ? "active" : ""}
          onClick={() => {
            setActiveCategory("inPerson");
            setItemList(ITEM_UPLOAD_EX);
          }}
        >
          직접 촬영
        </IntroductionCategoryListItem>
        <IntroductionCategoryListItem
          className={activeCategory === "professional" ? "active" : ""}
          onClick={() => {
            setActiveCategory("professional");
            setItemList(ITEM_REQUEST_EX);
          }}
        >
          전문 촬영
        </IntroductionCategoryListItem>
      </IntroductionImageCategoryList>
      <IntroductionImageWrapper ref={listRef}>
        <IntroductionImageFigure>
          <IntroductionImage src={activeCategory === "inPerson" ? bg_light : bg_dark} alt="" />
          <IntroduceProductListItem category={activeCategory} />
          <IntroductionFigcaption>
            {activeCategory === "inPerson" ? "직접촬영 제작 안내" : "전문촬영 제작 안내"}
          </IntroductionFigcaption>
        </IntroductionImageFigure>

        {itemList.map((itemId, idx) => {
          const faviewItem = data?.filter((item) => item.id === itemId)[0];
          if (faviewItem) {
            return (
              <IntroductionImageFigure
                onClick={() =>
                  window.open(
                    `https://faview.io/view/${
                      faviewItem.subdomain ? faviewItem.subdomain : faviewItem.id
                    }`
                  )
                }
              >
                <IntroductionImage src={faviewItem.img} alt="faviewItem Image" />
                <IntroductionFigcaption>{faviewItem.title}</IntroductionFigcaption>
              </IntroductionImageFigure>
            );
          }
        })}
      </IntroductionImageWrapper>
    </Fragment>
  );
};

const IntroductionImageCategoryList = styled.ul`
  position: relative;
  display: flex;
  gap: 24px;
  align-items: center;
  ${LayoutPadding};
  margin-bottom: 36px;
  .isDesktop && {
    margin-bottom: 40px;
  }
`;

const IntroductionCategoryListItem = styled.li`
  cursor: pointer;
  .isMobile && {
    ${Text_01};
    &::after {
      height: 2px;
    }
  }

  .isTablet &&,
  .isDesktop && {
    ${H_04};
    &::after {
      height: 4px;
    }
  }
  color: ${palette.neutral03};
  position: relative;

  &.active {
    color: ${palette.neutralNormal};
    &::after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      background: ${palette.neutralNormal};
      width: 100%;
    }
  }

  &:hover {
    color: ${palette.neutralNormal};
    transition: color 0.2s ease 0s;
  }

  &::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    background: ${palette.neutralNormal};
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
`;
const IntroductionImageWrapper = styled.div`
  display: flex;
  .isMobile && {
    gap: 8px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 70px;
  }

  position: relative;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .isTablet &&,
  .isDesktop && {
    gap: 12px;
    margin-bottom: 140px;
  }
  /** 화살표 */
`;

const IntroductionImage = styled.img`
  position: relative;
  object-fit: cover;
  object-position: center;
  cursor: pointer;

  .isMobile && {
    min-width: 320px;
    width: 80vw;
    height: 225px;
    ${device.mobile428} {
      width: 368px;
    }
  }
  .isTablet &&,
  .isDesktop && {
    width: 640px;
    height: 400px;
  }
`;

const IntroductionImageDescriptionWrapper = styled.ul`
  position: absolute;
  display: grid;
  align-items: center;

  .isMobile && {
    width: 300px;
    height: auto;
    top: 20px;
    left: 30px;
    row-gap: 6px;
    ${device.mobile428} {
      width: 310px;
      top: 20px;
      left: 50px;
      row-gap: 8px;
    }
  }

  .isTablet &&,
  .isDesktop && {
    width: 540px;
    height: auto;
    top: 40px;
    left: 50px;
    row-gap: 10px;
  }
`;

const IntroductionImageDescription = styled.li<{
  category?: IntroduceProductProps;
}>`
  background-color: rgba(255, 255, 255, 0.4);
  display: grid;
  grid-template-columns: 28px 1fr auto;
  align-items: center;

  .isMobile && {
    padding: 12px 14px;
    ${device.mobile428} {
      padding: 11.5px 14px;
    }
  }

  .isTablet &&,
  .isDesktop && {
    padding: 19px 12px 19px 40px;
    grid-template-columns: 40px 1fr auto;
  }
  &.title {
    filter: ${(props) => (props.category === "inPerson" ? "invert(0%)" : "invert(100%)")};

    display: flex;
    align-items: last baseline;
    background-color: transparent;

    .isMobile && {
      padding: 0;
      margin-bottom: 6px;
      gap: 8px;
    }

    .isTablet &&,
    .isDesktop && {
      padding: 0;
      gap: 12px;
      margin-bottom: 14px;
    }
  }
`;

const IntroductionNumber = styled.div`
  color: ${palette.primaryNormal};
  .isMobile && {
    ${Caption_13_B};
  }

  .isTablet &&,
  .isDesktop && {
    ${Text_01};
  }
`;

const IntroductionNumberTitle = styled.div<{
  category?: IntroduceProductProps;
}>`
  color: ${palette.neutralNormal};
  filter: ${(props) => (props.category === "inPerson" ? "invert(0%)" : "invert(100%)")};
  .isMobile && {
    ${Body_02};
  }

  .isTablet &&,
  .isDesktop && {
    ${Sub_02};
  }
`;

const IntroductionNumberButton = styled.button<{
  category?: IntroduceProductProps;
}>`
  color: ${(props) => (props.category === "inPerson" ? palette.neutral01 : palette.neutral04)};
  text-decoration: underline;
  text-decoration-color: ${(props) =>
    props.category === "inPerson" ? palette.neutral01 : palette.neutral04};

  .isMobile && {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    text-decoration-thickness: 0.5px;
    text-underline-offset: 1px;
  }

  .isTablet &&,
  .isDesktop && {
    ${Body_16};
    padding: 8px 10px;
    text-decoration-thickness: 1px;
    text-underline-offset: 6px;
  }
`;

const IntroductionImageDescriptionTitle = styled.div`
  color: ${palette.primary900};

  .isMobile && {
    ${Caption_13_B};
  }

  .isTablet &&,
  .isDesktop && {
    ${Text_01};
  }
`;

const IntroductionImageDescriptionContent = styled.p`
  ${palette.neutral01};
  .isMobile && {
    font-size: 8px;
    line-height: 4px;
    font-weight: 300;
  }

  .isTablet &&,
  .isDesktop && {
    ${Body_02};
    align-self: flex-end;
  }
`;
const IntroductionFigcaption = styled.figcaption`
  color: #000;
  .isMobile && {
    ${Text_02};
  }
  .isTablet &&,
  .isDesktop && {
    ${Text_01};
  }
`;

const IntroductionImageFigure = styled.figure`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default IntroduceProduct;
