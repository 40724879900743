import { palette } from "modules/defines/styles";
import { Body_16, Sub_16_B, Title_24_B } from "modules/style/typography";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

interface CardProps {
  firstTitle: string;
  secondTitle: string;
  description: string;
  imgSrc: string;
  imgPosition: number;
  isButton?: boolean;
}

const SampleArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5084 6.66675L17.5 13.3334H2.5V11.6694H14.174L11.1746 7.66347L12.5084 6.66675Z"
        fill="black"
      />
    </svg>
  );
};

const Card: React.FC<CardProps> = ({ ...props }) => {
  const { firstTitle, secondTitle, description, imgSrc, imgPosition, isButton } = props;
  return (
    <CardFigure isButton={isButton}>
      <CardTextWrapper>
        <CardTitle>
          <span>{firstTitle}</span>
          <span>{secondTitle}</span>
        </CardTitle>
        <CardDescription>{description}</CardDescription>
        {isButton && (
          <SampleButton onClick={() => (window.location.href = `https://faview.ai/list`)}>
            샘플 경험하기
            <SampleArrow />
          </SampleButton>
        )}
      </CardTextWrapper>
      <CardImage
        loading="lazy"
        imgPosition={imgPosition}
        src={imgSrc}
        width={229}
        height={183}
        alt=""
      />
    </CardFigure>
  );
};

const CardFigure = styled.figure<{ isButton?: boolean }>`
  width: 310px;
  height: 420px;
  padding-top: 40px;
  padding-bottom: 30px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: ${({ isButton }) => (isButton ? "0" : "37px")};
`;

const SampleButton = styled.button`
  ${Sub_16_B};
  padding: 8px 0;
  display: flex;
  gap: 8px;
`;
const CardTextWrapper = styled.figcaption`
  padding: 0 30px;
  display: grid;
  row-gap: 12px;
`;

const CardImage = styled.img<{ imgPosition: number }>`
  align-self: flex-end;
  margin-right: ${({ imgPosition }) => imgPosition}px;
`;

const CardTitle = styled.strong`
  & > span:first-of-type {
    ${Title_24_B};
    display: block;
    color: ${palette.neutral01};
  }
  & > span:last-child {
    ${Title_24_B};
    display: block;
    color: ${palette.primaryNormal};
  }
`;

const CardDescription = styled.p`
  ${Body_16};
  color: ${palette.neutral01};
`;
export default Card;
