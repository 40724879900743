import Header from "components/Layout/Header";
import styled, { css } from "styled-components";
import { blur4, noise } from "assets/img/index";
import { H_02, H_03, Text_01, Text_02, Title_24_B } from "modules/style/typography";
import { palette } from "modules/defines/styles";
import { useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import Footer from "components/Layout/Footer";
import Contact from "components/Contact";
import ListCardSwiper from "composite/ListCardSwiper";
import ToggleCarousel from "composite/ToggleCarousel";
import Carousel from "composite/Carousel";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Lenis from "lenis";
gsap.registerPlugin(ScrollTrigger, useGSAP);
const MainContainer = () => {
  const lenis = new Lenis();
  const typoWrapperRef = useRef<HTMLDivElement[] | null[]>([null]);
  const typoRef = useRef<HTMLParagraphElement[] | null[]>([null]);
  const sectionRef = useRef<HTMLElement>(null);

  useGSAP(() => {
    if (sectionRef.current && typoRef.current !== null) {
      const tl = gsap.timeline({});

      tl.set(typoRef.current[0], {
        color: "transparent",
      });
      tl.set(typoRef.current[1], {
        color: "transparent",
      });
      tl.set(typoRef.current[2], {
        color: "transparent",
      });
      tl.to(
        typoRef.current[0],
        {
          color: "rgba(255, 255, 255, 0.80)",
        },
        "+=1"
      );
      tl.to(
        typoRef.current[1],
        {
          color: "rgba(255, 255, 255, 0.80)",
        },
        "+=0.5"
      );
      tl.to(
        typoRef.current[2],
        {
          color: "rgba(255, 255, 255, 0.80)",
        },
        "+=0.5"
      );

      tl.play();
      // 애니메이션이 끝날 때 스크롤을 활성화
      // tl.eventCallback("onComplete", function () {
      //   document.body.style.overflow = "auto";
      // });

      gsap.utils.toArray(typoWrapperRef.current.slice(1)).forEach((item: any) => {
        gsap.to(item, {
          opacity: 1,
          yPercent: -300,
          ease: "none",
          duration: 0.5,
          scrollTrigger: {
            trigger: item,
            start: "top bottom",
            end: "bottom top",
            scrub: 0.5,
          },
        });
      });
    }
  });

  // lenis.on("scroll", (e: any) => {
  //   console.log(e);
  // }); --> test 용

  lenis.on("scroll", ScrollTrigger.update);

  gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
  });

  gsap.ticker.lagSmoothing(0);

  return (
    <div>
      <Header />
      <Blur height={window.innerHeight} ref={sectionRef} url={blur4}>
        <TypographyWrapper
          mobileLeft="50%"
          mobileTop="6%"
          tabletTop="10%"
          tabletLeft="50%"
          space={0}
          isAbsolute={true}
          desktopLeft={"50%"}
          desktopTop="8%"
          transform="translateX(-50%)"
          ref={(el) => (typoWrapperRef.current[0] = el)}
          className="typo"
        >
          <Typography ref={(el) => (typoRef.current[0] = el)} maxWidth={300}>
            언제 어디서든,
          </Typography>
          <Typography ref={(el) => (typoRef.current[1] = el)} maxWidth={300}>
            내가 원하는
          </Typography>
          <Typography ref={(el) => (typoRef.current[2] = el)} maxWidth={300}>
            3D 공간 경험
          </Typography>
        </TypographyWrapper>
        <TypographyWrapper
          isAbsolute={true}
          mobileLeft="50%"
          mobileTop="40%"
          tabletTop="40%"
          tabletLeft="50%"
          desktopLeft={"50%"}
          desktopTop="40%"
          transform="translateX(-50%)"
          ref={(el) => (typoWrapperRef.current[1] = el)}
          className="typo"
        >
          <ResponsiveTypography mobileWidth={256} tabletWidth={606} desktopWidth={606}>
            내가 만드는 3D 공간뷰어,
            <wbr /> 스마트폰으로 5분 만에
          </ResponsiveTypography>
        </TypographyWrapper>
        <TypographyWrapper
          mobileLeft="50%"
          mobileTop="60%"
          tabletTop="70%"
          tabletLeft="50%"
          isAbsolute={true}
          desktopLeft={"50%"}
          desktopTop="70%"
          transform="translateX(-50%)"
          space={52}
          ref={(el) => (typoWrapperRef.current[2] = el)}
          className="typo"
        >
          <ResponsiveTypography mobileWidth={177} tabletWidth={599} desktopWidth={599}>
            파뷰는 <wbr /> 어디서나 <wbr />
            자유롭게 <wbr /> 이용할 수 있어요.
          </ResponsiveTypography>
        </TypographyWrapper>
        <TypographyWrapper
          mobileLeft="50%"
          mobileTop="90%"
          tabletTop="90%"
          tabletLeft="50%"
          isAbsolute={true}
          desktopLeft={"50%"}
          desktopTop="90%"
          transform="translateX(-50%)"
          ref={(el) => (typoWrapperRef.current[4] = el)}
          className="typo"
        >
          <ResponsiveTypography mobileWidth={263} tabletWidth={329} desktopWidth={1000}>
            지금 무료로 <wbr /> 제작해보세요.
            <FreeButton
              onClick={() => {
                window.open("https://faview.ai/portal/mypage", "_blank");
              }}
              type="button"
            >
              무료로 제작하기
            </FreeButton>
          </ResponsiveTypography>
        </TypographyWrapper>
      </Blur>
      <ListCardSwiper />
      <CarouselSection>
        <Subtitle>
          언제 어디서든
          <br /> 디지털 트윈을 경험해 보세요.
        </Subtitle>
        <Carousel />
      </CarouselSection>
      <ToggleCarousel />
      <Contact />
      <Footer mode="dark" />
    </div>
  );
};

export const SectionLayout = styled.section<{ padding: string }>`
  padding: ${({ padding }) => padding};
`;

const Subtitle = styled.p`
  ${Title_24_B};
  color: #fff;
  text-align: center;
`;

const Blur = styled.section<{ url: string; height: number }>`
  background: url(${(props) => props.url}) no-repeat;
  height: ${({ height }) => height * 4}px;
  .isMobile && {
    background-size: cover;
    background-position: left 25% center;
    margin-top: 48px;
  }
  .isTablet && {
    background-size: cover;
    background-position: left 25% center;
    margin-top: 48px;
  }
  background-size: contain;
  position: relative;
  margin-top: 70px;
  width: 100%;

  &::after {
    content: "";
    background: url(${noise}) no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: ${({ height }) => height * 4}px;
    top: 0;
    left: 0;
  }
`;

const Section = styled.section`
  height: 820px;
  width: 100%;
  position: relative;
`;

const CarouselSection = styled(Section)`
  background-color: ${palette.neutralNormal};
  display: flex;
  flex-direction: column;
  .isMobile && {
    padding: 120px 0 140px 0;
    gap: 40px;
    @media (min-width: 428px) and (max-width: 767px) {
      padding: 120px 0 80px 0;
      gap: 60px;
    }
  }

  .isTablet && {
    padding: 120px 40px 80px 40px;
    gap: 60px;
  }
  .isDesktop && {
    padding: 120px 100px;
    gap: 60px;
    @media (min-width: 1920px) {
      padding: 120px 0px 80px 0px;
      gap: 60px;
    }
  }
`;

const FreeButton = styled.button`
  background-color: white;
  border-radius: 4px;
  color: black;
  z-index: 100;
  &:hover {
    border-radius: 4px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), #fff;
  }
  .isMobile && {
    padding: 18px 32px;
    ${Text_02};
    @media (min-width: 428px) and (max-width: 767px) {
      padding: 20px 32px;
      ${Text_01};
    }
  }

  .isTablet &&,
  .isDesktop && {
    padding: 20px 32px;
    ${Text_01};
  }
`;

interface TypographyProps {
  maxWidth?: number;
  isAbsolute?: boolean;
  space?: number;
  mobileTop: string;
  mobileLeft: string;
  tabletTop: string;
  tabletLeft: string;
  desktopTop: string;
  desktopLeft: string;
  transform: string;
}

const TypographyWrapper = styled.div<TypographyProps>`
  ${(props) =>
    props.isAbsolute &&
    css`
      position: absolute;
    `}

  .isMobile && {
    top: ${({ mobileTop }) => mobileTop};
    left: ${({ mobileLeft }) => mobileLeft};
  }

  .isTablet && {
    top: ${({ tabletTop }) => tabletTop};
    left: ${({ tabletLeft }) => tabletLeft};
  }

  .isDesktop && {
    top: ${({ desktopTop }) => desktopTop};
    left: ${({ desktopLeft }) => desktopLeft};
  }

  transform: ${({ transform }) => transform};
  width: 100%;

  max-width: ${({ maxWidth }) => maxWidth}px;

  display: grid;
  padding: 0 ${({ space }) => space}px;
`;

const Typography = styled.p<{ maxWidth?: number }>`
  text-align: center;
  white-space: pre;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  max-width: ${({ maxWidth }) => maxWidth}px;

  margin: 0 auto;
  color: rgba(255, 255, 255, 0.8);

  text-shadow: 0px 0px 1px ${palette.opacityB25};

  .isMobile && {
    gap: 32px;
    ${H_03};
    @media (min-width: 428px) and (max-width: 767px) {
      gap: 32px;
      ${H_02};
    }
  }

  .isTablet && {
    gap: 36px;
    ${H_02};
  }

  .isDesktop && {
    gap: 115px;
    ${H_02};
  }
`;

interface MaxWidthTypographyProps {
  mobileWidth: number;
  tabletWidth: number;
  desktopWidth: number;
}

const ResponsiveTypography = styled(Typography)<MaxWidthTypographyProps>`
  .isMobile && {
    white-space: pre-wrap;
    width: ${({ mobileWidth }) => mobileWidth}px;
  }

  .isTablet && {
    white-space: pre-line;
    max-width: ${({ tabletWidth }) => tabletWidth}px;
  }

  .isDesktop && {
    white-space: pre-line;
    max-width: ${({ desktopWidth }) => desktopWidth}px;
  }
`;

export default MainContainer;
