import { createRoot } from "react-dom/client";
import styled from "styled-components";
import App from "./app";
const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
  <>
    <App />
  </>
);
