import axios, { AxiosResponse } from "axios";
import { apiPaths } from "modules/defines/path";

export const apiAWS = axios.create({
  baseURL: process.env.REACT_APP_LOCAL ? apiPaths.awsLocalUrl : apiPaths.awsBaseUrl,
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true,
});

export const spaceAPI = {
  getAllSpace: () => apiAWS.get(apiPaths.getAllSpace),
};
