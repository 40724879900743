import { palette } from "modules/defines/styles";
import styled, { css } from "styled-components";

// button / solid
export const ButtonSolid = styled.button<{
  mode: string;
  size: string;
  marginTop: string;
}>`
  margin-top: ${(props) => props.marginTop || "0px"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  // Mode
  ${(props) =>
    props.mode === "dark" &&
    css`
      color: ${palette.black};
      background-color: ${palette.white};
      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), #fff;
      }
    `}

  ${(props) =>
    props.mode === "light" &&
    css`
      color: ${palette.white};
      background-color: ${palette.black};
      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), #fff;
      }
    `};

  // Size
  ${(props) =>
    props.size === "L" &&
    css`
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 150% */

      padding: 20px 32px;
    `}
  ${(props) =>
    props.size === "M" &&
    css`
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */

      padding: 18px 32px;
    `}
`;
