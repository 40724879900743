import { palette } from "modules/defines/styles";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { logo_faview } from "assets/img/index";
import { Text_02, Title_20 } from "modules/style/typography";
import { UserAgent } from "utils/userAgent";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import device from "modules/defines/device";

interface HamburgerNavButtonProps {
  checked: boolean;
  onclick: Function;
}
gsap.registerPlugin(useGSAP);
const HamburgerNavButton: React.FC<HamburgerNavButtonProps> = ({ onclick, checked }) => {
  return (
    <>
      <Toggle readOnly checked={checked} id="toggle" />
      <Hamburger onClick={() => onclick()} htmlFor="toggle">
        <div></div>
        <div></div>
        <div></div>
      </Hamburger>
    </>
  );
};

const Header = () => {
  const [checked, setChecked] = useState(false);
  const [attach, setAttach] = useState<Blob>(() => new Blob());
  const navRef = useRef<HTMLDivElement>(null);
  const { browser, os } = UserAgent;
  const onFileChange = (e: { target: { files: any } }) => {
    if (e.target.files.length === 0) {
      return;
    }
    const {
      target: { files },
    } = e;
    const theFile = files[0];
    setAttach(theFile);
    console.log(theFile);
  };
  // const reader = new FileReader();

  //

  // reader.onload = function () {
  //   console.log(reader.result);
  // };
  // reader.readAsDataURL(attach);

  // const testtpe = {
  //   name: "test",
  //   file: reader.result?.toString(),
  // };
  useEffect(() => {
    localStorage.setItem("attach", URL.createObjectURL(attach));
  }, [browser, attach]);

  return (
    <HeaderWrapper>
      <Link
        to="/"
        onClick={() => {
          if (window.location.pathname === "/") {
            window.location.reload();
          }
        }}
      >
        <FAVIEWLogo src={logo_faview} />
      </Link>
      <HamburgerNavButton
        checked={checked}
        onclick={() => {
          gsap.from(navRef.current, {
            opacity: 0,
            duration: 0.1,
            ease: "power1.inOut",
          });
          setChecked(!checked);
          gsap.to(navRef.current, {
            opacity: checked ? 0 : 1,
            duration: 0.1,
            ease: "power1.inOut",
          });
        }}
      />

      <Nav ref={navRef} className={checked ? "checked" : ""}>
        {/* <input type="file" onChange={onFileChange} /> */}
        <NavItem
          onClick={() => {
            setChecked(false);
          }}
          className="isFreeButton"
          href={"https://faview.ai/portal/mypage"}
          target="_blank"
        >
          무료로 제작하기
        </NavItem>
        <NavItem
          onClick={() => {
            setChecked(false);
          }}
          href={"https://favapp.io/corporate"}
          target="_blank"
        >
          회사 소개
        </NavItem>
        <NavItem
          onClick={() => {
            setChecked(false);
          }}
          href={`https://faview.ai/portal/pricing`}
          target="_blank"
        >
          요금 안내
        </NavItem>
        <NavItem
          onClick={() => {
            setChecked(false);
          }}
          href={`https://broz.notion.site/FAVIEW-cd0a3d703bf94af993c4feab4fbd4dea?pvs=4`}
          target="_blank"
        >
          가이드 센터
        </NavItem>
        <NavItem
          onClick={() => {
            setChecked(false);
          }}
          href={`https://broz.notion.site/FAVIEW-FAQ-c013b3a15e1045008fea2257920f8f34?pvs=4`}
          target="_blank"
        >
          자주 묻는 질문
        </NavItem>
      </Nav>
    </HeaderWrapper>
  );
};

const FAVIEWLogo = styled.img`
  height: 18px;
  .isDesktop && {
    height: 24px;
    margin: 10px 0;
  }
`;

const Nav = styled.nav`
  .isMobile &&,
  .isTablet && {
    z-index: 98;
    background-color: ${palette.opacityB80};
    display: grid;
    row-gap: 4px;
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    position: absolute;
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    &.checked {
      display: grid;
      opacity: 1;
    }
  }
  .isDesktop && {
    display: flex;
    gap: 21px;
  }
`;

const NavItem = styled.a`
  &.isFreeButton {
    background-color: ${palette.primaryNormal};
    .isDesktop && {
      order: 2;
      vertical-align: middle;
      display: flex;
      align-items: center;
      padding: 21px 13px;
    }
  }
  .isMobile && {
    padding: 22px 13px;
    ${Text_02};
    color: white;
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }

    @media (min-width: 428px) and (max-width: 767px) {
      padding: 16px 40px;
      ${Text_02};
      color: white;
      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .isTablet && {
    padding: 16px 40px;
    ${Text_02};
    color: white;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .isDesktop && {
    display: flex;
    align-items: center;
    padding: 5px;

    ${Title_20};
    color: white;
  }
`;

const Hamburger = styled.label`
  padding: 12px;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;

  div {
    background-color: white;
    position: relative;
    width: 18px;
    height: 2px;
    margin-top: 4px;
    transition: all 0.2s ease-in-out;
    border-radius: 35px;
  }

  .isDesktop && {
    display: none;
  }
`;

const Toggle = styled.input.attrs({ type: "checkbox" })`
  display: none;

  &:checked + ${Hamburger} div:nth-child(1) {
    transform: rotate(45deg);
    margin-top: 4px;
  }

  &:checked + ${Hamburger} div:nth-child(2) {
    transform: rotate(-45deg);
    margin-top: -2px;
  }

  &:checked + ${Hamburger} div:nth-child(3) {
    transform: scale(0);
  }
`;

const HeaderWrapper = styled.header`
  z-index: 9999;
  position: fixed;
  top: 0;

  background-color: ${palette.opacityB80};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .isMobile && {
    height: 48px;
    padding-left: 20px;
    padding-right: 8px;
    @media (min-width: 428px) and (max-width: 1024px) {
      padding-left: 40px;
      padding-right: 28px;
    }
  }

  .isTablet && {
    padding-left: 40px;
    padding-right: 28px;
    height: 48px;
  }

  .isDesktop && {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 46px;
    padding-right: 40px;
    max-height: 70px;
    margin: 0 auto;
    width: 100%;
    ${device.largeDesktop} {
      max-width: 1920px;
      padding-left: 340px;
      padding-right: 340px;
    }
  }
`;

export default Header;
