import { palette } from "modules/defines/styles";
import { Text_02 } from "modules/style/typography";
import styled, { css } from "styled-components";

const ButtonStyled = styled.button<{ styleType: "toggle" | "outline" }>`
  ${(props) =>
    props.styleType === "toggle" &&
    css`
      padding: 8px 12px;
      backdrop-filter: blur(100px);
      width: 169px;
      height: 44px;
      color: ${palette.neutral02};
      ${Text_02};
      &.active {
        z-index: 2;
        ${Text_02};
        background-color: white;
        color: ${palette.primaryNormal};
        border-radius: 10px;
      }
    `}

  ${(props) =>
    props.styleType === "outline" &&
    css`
      width: 100%;
      padding: 18px 32px;
      ${Text_02};
      text-align: center;
      color: white;
      border-radius: 4px;
      border: 1px solid white;
      &:hover {
        border-radius: 4px;
        border: 1px solid #fff;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), #fff;
      }
    `}
`;
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styleType: "toggle" | "outline";
}

const Button: React.FC<ButtonProps> = ({ styleType, ...rest }) => {
  return (
    <ButtonStyled styleType={styleType} {...rest}>
      {rest.children}
    </ButtonStyled>
  );
};

export default Button;
