import { img1, img2, img3, img4, img5 } from "assets/img";
import Card from "components/Card";
import { SwiperContainer } from "components/Layout/Swiper";
import { SwiperSlide, Swiper } from "swiper/react";

const Carousel: React.FC = () => {
  return (
    <div>
      <SwiperContainer>
        <Swiper
          breakpoints={{
            360: {
              slidesPerView: 1.1,
            },
            390: {
              slidesPerView: 1.18,
            },
            414: {
              slidesPerView: 1.25,
            },
            428: {
              slidesPerView: 1.25,
            },
            600: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2.1,
            },
            800: {
              slidesPerView: 2.6,
            },
            820: {
              slidesPerView: 2.3,
            },
            1024: {
              slidesPerView: 2.1,
            },
            1240: {
              slidesPerView: 3.1,
            },
            1920: {
              slidesPerView: 3.1,
            },
          }}
          spaceBetween={12}
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
        >
          <SwiperSlide>
            <Card
              imgPosition={3}
              firstTitle={"언제 어디서든"}
              secondTitle={"랜선공간투어"}
              description={
                "반응형으로 제작되어 PC와 모바일로 언제 어디서든 360도 공간을 경험하여 시간과 비용을 절약할 수 있습니다."
              }
              imgSrc={img1}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              imgPosition={10}
              firstTitle={"사진 그 이상의"}
              secondTitle={"공간 경험"}
              description={
                "미니맵, 층간이동, XR콘텐츠 등 공간 내부를 효과적으로 경험할 수 있도록 다양한 기능을 제공합니다."
              }
              imgSrc={img2}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              imgPosition={20}
              firstTitle={"효율적이고 신선한"}
              secondTitle={"온라인 마케팅"}
              description={
                "경쟁업체 사이에서 방문율을 올릴 수 있는 새로운 마케팅 전략의 홍보 수단을 제공합니다."
              }
              imgSrc={img3}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              imgPosition={20}
              firstTitle={"타사 대비"}
              secondTitle={"저렴한 가격"}
              description={
                "외부 API를 사용하지 않은 자사가 직접 개발한 서비스로 보다 저렴한 가격으로 제공합니다."
              }
              imgSrc={img4}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Card
              isButton
              imgPosition={17}
              firstTitle={"공간 특징별"}
              secondTitle={"커스터마이즈 가능"}
              description={"커스터마이즈 기능을 통해 공간을 더욱 효과적으로 표현할 수 있습니다."}
              imgSrc={img5}
            />
          </SwiperSlide>
        </Swiper>
      </SwiperContainer>
    </div>
  );
};

export default Carousel;
