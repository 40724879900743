import { Layout, Wrapper } from "components/Layout/Layout";
import { SwiperContainer } from "components/Layout/Swiper";
import ProcessCard from "components/ProcessCard";
import { palette } from "modules/defines/styles";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "components/Button";
import { useEffect, useState } from "react";
import { blur3, blur3_tablet } from "assets/img";

const ToggleCarousel = () => {
  const [isToggle, setIsToggle] = useState(0);

  useEffect(() => {}, [isToggle]);
  return (
    <ToggleCarouselLayout>
      <ToggleLayout padding="0 20px">
        <ToggleWrapper>
          <Button
            id="inPerson"
            className={isToggle === 0 ? "active" : ""}
            onClick={() => {
              setIsToggle(0);
            }}
            type="button"
            styleType="toggle"
          >
            직접 촬영
          </Button>
          <Button
            id="professional"
            className={isToggle === 1 ? "active" : ""}
            onClick={() => {
              setIsToggle(1);
            }}
            type="button"
            styleType="toggle"
          >
            전문 촬영
          </Button>
        </ToggleWrapper>
      </ToggleLayout>
      <SecondCarousel toggle={isToggle} />
      <ButtonWrapper>
        <Button
          onClick={() => {
            window.open(
              "https://broz.notion.site/FAVIEW-Light-cd0a3d703bf94af993c4feab4fbd4dea",
              "_blank"
            );
          }}
          type="button"
          styleType="outline"
        >
          영상 촬영 가이드
        </Button>
        <Button
          onClick={() => {
            window.open("https://faview.ai/portal/mypage", "_blank");
          }}
          type="button"
          styleType="outline"
        >
          무료로 제작하기
        </Button>
      </ButtonWrapper>
    </ToggleCarouselLayout>
  );
};

const ToggleLayout = styled(Layout)`
  .isMobile && {
    padding: 0 20px;
    @media (min-width: 428px) and (max-width: 767px) {
      padding: 0 40px;
    }
  }
  .isTablet && {
    padding: 0 40px;
  }
  .isDesktop && {
    padding: 0 0;
  }
`;

const ToggleCarouselLayout = styled.section`
  .isMobile && {
    background: url(${blur3}) no-repeat;

    padding: 184px 0 128px 0;
    background-size: cover;
    @media (min-width: 428px) and (max-width: 767px) {
      background: url(${blur3}) no-repeat;

      padding: 184px 0 50px 0;
      background-size: cover;
    }
  }
  .isTablet && {
    background: url(${blur3_tablet}) no-repeat;

    padding: 120px 40px 80px 40px;
    gap: 60px;
    background-size: cover;
  }
  .isDesktop && {
    background: url(${blur3}) no-repeat;

    padding: 184px 0 130px 0;
    background-size: 110%;
  }
`;

const ButtonWrapper = styled(Wrapper)`
  gap: 20px;

  .isMobile && {
    padding: 40px 20px 0 20px;
    @media (min-width: 428px) and (max-width: 767px) {
      padding: 40px 40px 0 40px;
    }
  }

  .isTablet && {
    flex-direction: row;
    padding: 40px 0 0 0;
  }
  .isDesktop && {
    flex-direction: row;
    max-width: 1000px;
    padding: 40px 0 0 0;
    margin: 0 auto;
  }
`;

const ToggleWrapper = styled(Wrapper)`
  flex-direction: row;
  width: 100%;
  gap: 6px;
  border: 1px solid ${palette.neutral02};
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  padding: 4px;
  min-width: 320px;
  margin: 0 auto;

  .isTablet &&,
  .isDesktop && {
    width: fit-content;
    margin: 0 auto;
  }
`;

interface SecondCarouselProps {
  toggle: number;
}

const SecondCarousel: React.FC<SecondCarouselProps> = ({ toggle }) => {
  const list = [
    {
      "0": [
        {
          number: "01",
          title: "영상 촬영 및 업로드",
          isEmComment: false,
          descriptionSection1: "공간을 직접 영상으로 촬영하여 ",
          descriptionSection2: "FAVIEW Portal 에서 ",
          descriptionSection3: "파뷰 만들기 > 직접 촬영'에 업로드",
        },
        {
          number: "02",
          title: "3D 공간뷰어 제작",
          isEmComment: true,
          descriptionSection1: "업로드 된 공간은 AI 기술을 통해",
          descriptionSection2: "자동화 제작 되며, 소요 시간은 서버의 상황",
          descriptionSection3: "에 따라 추가될 수 있습니다.",
          emComment: "제작 시간 평균 30분 소요",
        },
        {
          number: "03",
          title: "내 공간 뷰어 관리",
          isEmComment: false,
          descriptionSection1: "FAVIEW Portal 에서",
          descriptionSection2: "제작하신 공간뷰어를 관리할 수 있습니다.",
          descriptionSection3: "",
        },
      ],
    },

    {
      "1": [
        {
          number: "01",
          title: "촬영 문의",
          title2: "및 견적서 작성",
          isEmComment: true,
          descriptionSection1: "영업팀과 상담 진행",
          descriptionSection2: "",
          descriptionSection3: "",
          emComment: "영업일 기준 최대 3일 소요",
        },
        {
          number: "02",
          title: "방문 및 공간 촬영",
          isEmComment: false,
          descriptionSection1: "전문 촬영팀이 방문하여 insta 360 으로 공간 촬영 진행",
          descriptionSection2: "",
          descriptionSection3: "",
        },
        {
          number: "03",
          title: "3D 공간뷰어 제작",
          isEmComment: true,
          descriptionSection1: "촬영된 공간을 3D 공간뷰어로 제작",
          descriptionSection2: "",
          descriptionSection3: "",
          emComment: "영업일 기준 최대 7일 소요",
        },
        {
          number: "04",
          title: "웹 뷰어 링크 전달",
          isEmComment: false,
          descriptionSection1: "언제 어디서든 공간뷰어를 볼 수 있도록 웹 뷰어 링크 제공",
          descriptionSection2: "",
          descriptionSection3: "",
        },
      ],
    },
  ];

  return (
    <Layout padding="80px 0 0 0">
      <SwiperContainer>
        <Swiper
          effect="fade"
          breakpoints={{
            360: {
              slidesPerView: 1.05,
              spaceBetween: 10,
            },
            390: {
              slidesPerView: 1.14,
              spaceBetween: 10,
            },
            428: {
              slidesPerView: 1.25,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2.1,
              spaceBetween: 10,
            },
            1240: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1920: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          grabCursor={true}
        >
          {list.map((item, index) => {
            return item[toggle === 0 ? 0 : 1]?.map((process) => {
              return (
                <SwiperSlide id={index.toString()} key={process.title}>
                  <ProcessCard {...process} />
                </SwiperSlide>
              );
            });
          })}
        </Swiper>
      </SwiperContainer>
    </Layout>
  );
};

export default ToggleCarousel;
