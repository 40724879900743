import { palette } from "modules/defines/styles";
import { Body_02, Text_01, Text_02 } from "modules/style/typography";
import styled from "styled-components";
interface ListCardProps {
  active?: boolean;
  onclick: () => void;
  summary: string;
  firstTitle: string;
  secondTitle: string;
  description: string;
}

const ListCard = ({ ...props }: ListCardProps) => {
  const { active, summary, firstTitle, secondTitle, description, onclick } = props;

  return (
    <ListCardWrapper onClick={onclick} className={active ? "active" : ""}>
      <Summary>{summary}</Summary>
      <Title>
        {firstTitle}&nbsp;
        <wbr />
        {secondTitle}
      </Title>
      <Description>{description}</Description>
    </ListCardWrapper>
  );
};

const ListCardWrapper = styled.ul`
  padding: 16px 24px;
  width: 100%;
  aspect-ratio: 360 / 214;
  border-radius: 20px;
  cursor: grabbing;

  transition: opacity 1s ease-in-out;

  .isMobile && {
    /* opacity: 0;
    scale: 1.5; */
    opacity: 1;
    cursor: default;
    &:hover {
      pointer-events: none;
    }
  }

  .isTablet && {
    opacity: 0.5;
    &:hover {
      opacity: 1;
      transform: opacity 0.5s ease-in-out;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6) inset;
      background: rgba(255, 255, 255, 0.2);
    }
    &.active {
      opacity: 1;
      transform: opacity 0.5s ease-in-out;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6) inset;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .isDesktop && {
    opacity: 0.5;
    &:hover {
      opacity: 1;
      transform: opacity 0.5s ease-in-out;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6) inset;
      background: rgba(255, 255, 255, 0.2);
    }
    @media (min-width: 1920px) {
      aspect-ratio: 540 / 156;
    }
    &.active {
      opacity: 1;
      transform: opacity 0.5s ease-in-out;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.6) inset;
      background: rgba(255, 255, 255, 0.2);
    }
  }
`;

const Summary = styled.li`
  ${Text_02};
  color: ${palette.primaryNormal};
`;

const Title = styled.li`
  ${Text_01};
  color: white;
  white-space: pre;
`;
const Description = styled.li`
  ${Body_02};
  color: white;
  min-width: 312px;
  height: auto;
  margin-top: 16px;
  .isDesktop && {
    @media (min-width: 1920px) {
      max-width: 492px;
    }
  }
`;
export default ListCard;
