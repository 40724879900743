import { useGSAP } from "@gsap/react";
import { img1_list, img2_list, bg3, img4_list } from "assets/img";
import ListCard from "components/ListCard";
import gsap from "gsap";
import { palette } from "modules/defines/styles";
import { H_04, H_03 } from "modules/style/typography";
import { useState, useRef, useEffect } from "react";
import { Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

const ListCardSwiper = () => {
  const [index, setIndex] = useState(1);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [isDown, setIsDown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);
  gsap.registerPlugin(useGSAP, ScrollTrigger);
  const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
  useGSAP(() => {
    if (isMobile && listContainerRef.current !== null) {
      let timeline = gsap.timeline({
        scrollTrigger: {
          trigger: listContainerRef.current,
          start: "top bottom",
          end: "bottom center",
          scrub: 1,
          markers: false,
        },
      });

      Array.from(listContainerRef.current.children).forEach((child, index) => {
        console.log(child, "child");
        timeline.from(child.childNodes, {
          opacity: 0,
          y: 20,
          markers: false,
          ease: "easeInOut",
        });
      });
    }
  }, []);
  const startSwipe = (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    setIsDown(true);
    const x = "touches" in e ? e.touches[0].pageX : e.pageX;
    setStartX(x);
    if (containerRef.current) {
      setScrollLeft(containerRef.current.scrollLeft);
    }

    if (window.innerWidth >= 1920) {
      const y = "touches" in e ? e.touches[0].pageY : e.pageY;
      setStartY(y);
      if (containerRef.current) {
        setScrollTop(containerRef.current.scrollTop);
      }
    }
  };

  const moveSwipe = (e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    if (!isDown) return;
    e.preventDefault();
    const x = "touches" in e ? e.touches[0].pageX : e.pageX;
    const walk = (startX - x) * 2;
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft + walk;
    }

    if (window.innerWidth >= 1920) {
      const y = "touches" in e ? e.touches[0].pageY : e.pageY;
      const walk = (startY - y) * 2;
      if (containerRef.current) {
        containerRef.current.scrollTop = scrollTop + walk;
      }
    }
  };
  const endSwipe = () => {
    setIsDown(false);
  };
  useEffect(() => {}, [index]);
  const list = [
    {
      id: 1,
      summary: "VR 투어",
      firstTitle: "실제 공간을 생생하게",
      secondTitle: "담아드립니다",
      description:
        "파뷰는 공간 내부를 자유롭게 탐험하며 공간의 오프라인 경험을 온라인에서 접할 수 있는 3D 공간뷰어 서비스입니다.",
    },
    {
      id: 2,
      summary: "미니맵",
      firstTitle: "손쉬운 조작으로 공간을",
      secondTitle: "쉽게 둘러볼 수 있어요",
      description:
        "미니맵을 통해 공간 내부의 구조를 직접 방문하지 않아도 쉽게 파악할 수 있고, 원하는 위치로 이동이 용이합니다.",
    },
    {
      id: 3,
      summary: "반응형 웹",
      firstTitle: "어디서나 자유롭게",
      secondTitle: "이용할 수 있어요",
      description:
        "파뷰는 반응형 웹으로 제공되어 스마트폰, 태블릿, 데스크탑 등 모든 환경에서 자유롭게 사용할 수 있습니다.\n커스터아미징 버전을 선택하시면 VR 기기도 가능합니다.",
    },
    {
      id: 4,
      summary: "태그 기능",
      firstTitle: "새로운 마케팅 수단으로",
      secondTitle: "사용해 보세요",
      description:
        "공간 뷰어 내에 댓글 달기 기능이나 제품을 3D 오브젝트로 띄워 홍보하며 구매를 유도할 수 있으며 이미지나 영상을 띄울 수도 있습니다.",
    },
  ];

  const image = () => {
    switch (index) {
      case 1:
        return <ListCardImage src={img1_list} alt="img1" />;
      case 2:
        return <ListCardImage src={img2_list} alt="img2" />;
      case 3:
        return <ListCardImage src={bg3} alt="img3" />;
      case 4:
        return <ListCardImage src={img4_list} alt="img4" />;
      default:
        return <ListCardImage src={img1_list} alt="img1" />;
    }
  };
  return (
    <ListCardSectionLayout ref={listContainerRef} padding="0px 120px">
      <ListTitle>기능</ListTitle>
      {isMobile ? (
        <Fragment>
          <ListCardImage src={img1_list} alt="img1" />
          <ListCard
            active={false}
            onclick={() => {}}
            summary={list[0].summary}
            firstTitle={list[0].firstTitle}
            secondTitle={list[0].secondTitle}
            description={list[0].description}
          />
          <ListCardImage src={img2_list} alt="img1" />
          <ListCard
            active={false}
            onclick={() => {}}
            summary={list[1].summary}
            firstTitle={list[1].firstTitle}
            secondTitle={list[1].secondTitle}
            description={list[1].description}
          />
          <ListCardImage src={bg3} alt="img1" />
          <ListCard
            active={false}
            onclick={() => {}}
            summary={list[2].summary}
            firstTitle={list[2].firstTitle}
            secondTitle={list[2].secondTitle}
            description={list[2].description}
          />
          <ListCardImage src={img4_list} alt="img1" />
          <ListCard
            active={false}
            onclick={() => {}}
            summary={list[3].summary}
            firstTitle={list[3].firstTitle}
            secondTitle={list[3].secondTitle}
            description={list[3].description}
          />
        </Fragment>
      ) : (
        <Fragment>
          {image()}
          <ListSwiperContainer
            ref={containerRef}
            onMouseDown={startSwipe}
            onMouseMove={moveSwipe}
            onMouseUp={endSwipe}
            onMouseLeave={endSwipe}
            onTouchStart={startSwipe}
            onTouchMove={moveSwipe}
            onTouchEnd={endSwipe}
          >
            {list.map((item) => (
              <ListCard
                active={item.id === index}
                onclick={() => {
                  setIndex(item.id);
                  if (item.id !== 1) {
                    containerRef.current?.scrollTo({
                      left: containerRef.current.scrollLeft * 0.5 + 100 * item.id,
                      behavior: "smooth",
                    });
                    // containerRef.current?.scrollTo({
                    //   top: containerRef.current.scrollTop * 0.5 + 50 * item.id,
                    //   behavior: "smooth",
                    // });
                  } else {
                    containerRef.current?.scrollTo({
                      left: 0,
                      behavior: "smooth",
                    });
                    // containerRef.current?.scrollTo({
                    //   top: 0,
                    //   behavior: "smooth",
                    // });
                  }
                }}
                key={item.id}
                {...item}
              />
            ))}
          </ListSwiperContainer>
        </Fragment>
      )}
    </ListCardSectionLayout>
  );
};

const SectionLayout = styled.section<{ padding: string }>`
  padding: ${({ padding }) => padding};
`;
const ListTitle = styled.h3`
  color: ${palette.white};

  align-self: center;
  ${H_04};
  .isTablet && {
    ${H_03};
    margin-bottom: 10px;
  }
  .isDesktop && {
    ${H_03};
    margin-bottom: 20px;
    place-self: center;
    @media (min-width: 1920px) {
      grid-area: title;
      place-self: center;
    }
  }
`;

const ListCardImage = styled.img`
  .isMobile && {
    width: 320px;
    height: 206px;
    margin: 40px auto 0 auto;
  }
  .isTablet && {
    margin: 0;
    width: 688px;
    height: 444px;
    object-fit: cover;
    align-self: center;
  }
  .isDesktop && {
    grid-area: img;
    margin: 0;
    width: 658px;
    height: 424px;
    object-fit: cover;
    align-self: center;
  }
`;
const ListSwiperContainer = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  .isDesktop && {
    max-width: 100%;

    @media (min-width: 1920px) {
      grid-area: swiper;
      overflow-y: scroll;
      max-height: 424px;
      flex-direction: column;
    }
  }
`;

const ListCardSectionLayout = styled(SectionLayout)`
  background-color: ${palette.neutralNormal};
  display: flex;
  flex-direction: column;
  .isMobile && {
    padding: 120px 0;
    gap: 20px;
  }
  .isTablet && {
    padding: 100px 40px 104px 40px;
    gap: 30px;
    @media (min-width: 428px) and (max-width: 768px) {
      padding: 120px 34px 100px 34px;
    }
  }
  .isDesktop && {
    gap: 40px;
    padding: 100px 0px 104px 120px;
    @media (min-width: 1920px) {
      padding: 100px 0px 104px 0px;
      display: grid;
      grid-template-columns: 658px 540px;
      row-gap: 40px;
      column-gap: 40px;
      place-content: center;
      grid-template-areas:
        "title title"
        "img swiper";
    }
  }
`;

export default ListCardSwiper;
