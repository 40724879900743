import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const IframeContainer = ({ match }) => {
  const location = useLocation();
  const url = `https://iframe.faview.ai${match.url}${location.search}`;

  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    updateViewportHeight();

    window.addEventListener("resize", updateViewportHeight);

    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);

  return (
    <Container>
      <StyledIframe src={url} title={match.url} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(
    var(--vh, 1vh) * 100
  ); /* 100% of the viewport height minus address bar */
  overflow: hidden;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export default IframeContainer;
