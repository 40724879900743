import { LayoutPadding, palette } from "modules/defines/styles";
import { H_04, H_02 } from "modules/style/typography";
import styled from "styled-components";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
const Title = () => {
  const titleRef = useRef<HTMLSpanElement[] | null[]>([]);

  useGSAP(() => {
    gsap.from(titleRef.current, {
      autoAlpha: 0,
      duration: 2,
      stagger: 0.5, // 각 애니메이션 사이의 지연 시간
    });

    gsap.to(titleRef.current[2], {
      color: palette.primaryNormal,
      delay: 2.2,
      // onComplete: () => {
      //   gsap.to(titleRef.current[2], {
      //     color: palette.neutralNormal,
      //     backgroundColor: "transparent",
      //     delay: 1.6,
      //     duration: 1,
      //   });
      // },
    });
  }, []);
  return (
    <ListTitle>
      <ListTitleChild ref={(el) => (titleRef.current[0] = el)}>쉽고,&nbsp;</ListTitleChild>
      <ListTitleChild ref={(el) => (titleRef.current[1] = el)}>
        빠르게 <wbr />
      </ListTitleChild>
      <ListTitleChild ref={(el) => (titleRef.current[3] = el)}>
        <ListTitleChild ref={(el) => (titleRef.current[2] = el)}>파뷰</ListTitleChild>로
        만들었습니다
      </ListTitleChild>
    </ListTitle>
  );
};

const ListTitle = styled.h2`
  width: 100%;
  max-width: 498px;
  margin-bottom: 72px;
  white-space: pre;
  ${LayoutPadding};
`;

const ListTitleChild = styled.span`
  color: ${palette.neutralNormal};
  .isMobile && {
    ${H_04};
  }
  .isTablet &&,
  .isDesktop && {
    ${H_02};
  }
`;

export default Title;
